import React from 'react'
import { useNavigate } from 'react-router-dom'
import jiom from '../images/jiom.png'

export default function JioMart() {
  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>JIO MART</h2>
    </div>
    <div className='container'>
     <div className='row'>
    
      <div className='col animate__animated animate__pulse animate__infinite	infinite text-center '>
         
           <img src={jiom} style={{maxWidth:"350px"}}/>

  
      </div>
      
      
      
     </div>
     <br/><br/>
     <div className='container'>
      <div className='row'>
      
          <div className='col animate__animated animate__slideInRight '>
          <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Jio Mart Account Management</h4></div> 

        <br/><br/>
        <p className='font-size'>Managing your Jio Mart account is essential for maximizing your sales and visibility on one of India's largest e-commerce platforms. At Mazeflip Services, we offer comprehensive account management solutions tailored to meet the unique needs of your Jio Mart store. Our dedicated team of account management experts works diligently to optimize your Jio Mart account for success, ensuring that you reach your target audience and achieve your sales goals.</p>
        <p className='font-size'>From account setup and configuration to ongoing monitoring and optimization, we handle all aspects of Jio Mart account management with precision and expertise. We help you navigate Jio Mart's platform features, policies, and guidelines, ensuring that your products are presented in the best possible light to potential customers. With Mazeflip Services managing your Jio Mart account, you can focus on creating compelling products and delivering exceptional customer experiences.</p>
       
        <br/>   
          </div>


          <div className='col animate__animated animate__slideInRight '>
          <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Jio Mart Onboarding and Launch</h4></div> 

        <br/><br/>
        <p className='font-size'>Navigating the onboarding process for Jio Mart requires a comprehensive understanding of the platform's requirements and procedures. At Mazeflip Services, we specialize in Jio Mart account onboarding, guiding you through each step with precision and expertise. Our experienced team of professionals ensures a smooth and efficient onboarding experience, helping you establish your presence on Jio Mart with confidence.</p>
        <p className='font-size'>We handle all aspects of the onboarding process, from account registration and documentation to verification and approval. Our goal is to expedite your entry into the Jio Mart ecosystem while ensuring compliance with all platform requirements and guidelines. With Mazeflip Services overseeing your Jio Mart account onboarding, you can rest assured that your journey with one of India's largest e-commerce platforms will be seamless and hassle-free.</p>
        <br/>   
          </div>


          <div className='col animate__animated animate__slideInRight '>
       <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Jio Mart Catalog Management (Listing)</h4></div> 
        <br/><br/>
        <p className='font-size'>Effective cataloging of your products on Jio Mart is essential for attracting customers and driving sales on India's premier e-commerce platform. At Mazeflip Services, we specialize in cataloging solutions designed to optimize your product listings for maximum visibility and engagement on Jio Mart. Our experienced team of cataloging experts meticulously categorizes, tags, and organizes your products, ensuring that they stand out amidst the competition and appeal to Jio Mart's vast customer base.</p>
        <p className='font-size'>We employ industry-leading techniques and best practices to enhance the discoverability and appeal of your products on Jio Mart. From crafting compelling product titles and descriptions to selecting high-quality images and optimizing pricing strategies, we take a strategic approach to cataloging your products on Jio Mart. Our goal is to help you increase your sales, expand your customer base, and establish a strong presence in the competitive e-commerce market.</p>
        <br/>   
          </div>
          

      </div>
     </div>
       <div className='color-change text-center'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>
    </div>
      
    </>
  )
}
