import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

const BASE_URL = 'https://mazeflipservices.com:44344'; 



export default function UserRegister() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [role , setRole] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}/admin/createad`, {
        username,
        email,
        mobile,
        password,
        role
        
      });

      if (response.status === 201) {
        // User registration successful
        // Handle any success message or redirection
        setShowAlert(true);
        setTimeout(() => {
          navigate('/admin-login');
        }, 3000);
       

      }
      
      
      
      else {
        setError('User registration failed');
      }
    } catch (error) {
      setError('Error occurred during user registration');
    }

    setUsername('');
    setPassword('');
    setEmail('');
    setMobile('');
    setRole('');
  };
  return (
    <div>
        <div  align="center">
        <h2 align="center">Mazeflip Registration Page</h2><br/><br/><br/>
        <div align="center"  style={{width:'350px', border: '1px solid black'}}>
          <br/>
        <div><h3>Create Account</h3></div>  
        <div align="center">
          <form style={{width:'300px'}} method='post' onSubmit={handleSubmit} >
         
          <label htmlFor='username'>Your Name</label>
          <input id='username' type='text' placeholder='Enter your full name' className='form-control'
           required value={username} onChange={(e)=>{setUsername(e.target.value)}}/><br/>
          <label htmlFor='email'>Email</label>
          <input type='email' id='email' placeholder='Enter your Email' className='form-control'
           required value={email} onChange={(e)=>{setEmail(e.target.value)}}/><br/>
          <label htmlFor='mobile'>Mobile</label>
          <input type='text' id='mobile' placeholder='Enter your Mobile Number' className='form-control'
           required value={mobile} onChange={(e)=>{setMobile(e.target.value)}}/><br/>
          <label htmlFor='password'>Password</label>
          <input type='password' id='password' placeholder='Password min 8 char long' className='form-control'
           required minLength={8} value={password} onChange={(e)=>{setPassword(e.target.value)}}/><br/>
           <label htmlFor='role'>Role</label>
          <input type='text' id='role' placeholder='Enter the Role' className='form-control'
           required  value={role} onChange={(e)=>{setRole(e.target.value)}}/><br/>
          <button type='submit' className='btn btn-primary'>Submit</button><br/><br/>

             
          </form>
          {error && <p>{error}</p>}
          </div>
          
        </div>
        

        </div>
        {showAlert && (
  <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
    User added successfully!
  </Alert>
)}
      
    </div>
  )
}
