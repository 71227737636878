import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import './CareerPage.css';

const BASE_URL = 'https://mazeflipservices.com:44344'; 

export default function CareerPage() {
  const [selectedCarOption, setSelectedCarOption] = useState('');
  const [positionOptions, setPositionOptions] = useState([]);
  const [submissionCarStatus, setSubmissionCarStatus] = useState(null);
  const [departmentsList, setDepartmentsList] = useState([]);

  // useEffect(() => {
  //   axios.get(`${BASE_URL}/posts/all`)
  //     .then(response => {
  //       setPositionOptions(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching service options:', error);
  //     });

  //   axios.get(`${BASE_URL}/dept/withposts`)
  //     .then(response => {
  //       setDepartmentsList(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching departments list:', error);
  //     });
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/posts/all`);
        if (response.status === 200) {
          setPositionOptions(response.data);
        } else {
          // Handle unexpected status codes
          console.error('Unexpected status code:', response.status);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error('Error fetching service options:', error);
      }
    };

    fetchData();
  }, []);

  ////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const fetchDepData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/dept/withposts`);
        if (response.status === 200) {
          setDepartmentsList(response.data);
        } else {
          // Handle unexpected status codes
          console.error('Unexpected status code:', response.status);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error('Error fetching service options:', error);
      }
    };

    fetchDepData();
  }, []);

  /////////////////////////////////////////////////////////////////////////////

  const handleCarChange = (event) => {
    setSelectedCarOption(event.target.value);
  };

  const handleCarSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const requestCarData = {
      fullName: formData.get('fullName'),
      email: formData.get('email'),
      mobileNumber: formData.get('mobileNumber'),
      positionApplied: {
        posId: parseInt(selectedCarOption)
      },
    };

    try {
      const response = await axios.post(`${BASE_URL}/usercp/createcp/${selectedCarOption}`, requestCarData);
      console.log('Response:', response.data);
      setSubmissionCarStatus('success');
      setSelectedCarOption('');
      event.target.reset();

      setTimeout(() => {
        setSubmissionCarStatus(null);
      }, 3000);
    } catch (error) {
      console.error('Error creating career page:', error);
    }
  };

  return (
    <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
        <h2 className='fw-bold text-center'>
          CAREER
        </h2>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <h5 className='text-center fw-bold'>EXPLORE OPPORTUNITIES</h5>
            <p className='text-center'>Ready to embark on your journey with us? Join our team of innovators and achievers. Take the first step by completing the form below, and we'll be in touch shortly.</p>
            <div className='border border-dark rounded p-4'>
              <div className='container'>
              <h3 className='text-center'>APPLY NOW</h3>
              <form onSubmit={handleCarSubmit} className='form-group'>
                <div className='row car-form'>
                  <div className='col'>
                    <label htmlFor='fullName'>Full Name*</label>
                    <input type='text' id='fullName' name='fullName' className='form-control' required />
                  </div>
                  <div className='col'>
                    <label htmlFor='email'>Email*</label>
                    <input type='text' id='email' name='email' className='form-control' required />
                  </div>
                </div>
                <div className='row car-form'>
                  <div className='col'>
                    <label htmlFor='mobileNumber'>Mobile Number*</label>
                    <input type='text' id='mobileNumber' name='mobileNumber' className='form-control' required />
                  </div>
                </div>
                <div className='row car-form'>
                  <div className='col'>
                    <label htmlFor='positionApplied'>Position Applied For*</label><br/>
                    <select onChange={handleCarChange} value={selectedCarOption} id='positionApplied' className='form-control text-muted'>
                      <option value="">Please Select</option>
                      {positionOptions.map((option, index) => (
                        <option key={index} value={option.posId}>{option.posName}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col text-center'>
                    <button type='submit' className='btn btn-primary'>Submit</button>
                  </div>
                </div>
              </form>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <h5 className='text-center fw-bold'>CURRENT OPENINGS</h5>
            <p className='text-center'>Discover Exciting Career Opportunities: Explore the Latest Openings to Propel Our Company Forward.</p>
            <div className='container'>
              {departmentsList.map((department, index) => (
                <div key={index}>
                  <h5 className='text-center fw-bold text-danger'>{department.dName}</h5>
                  {department.positions.map((position, posId) => (
                    <div key={posId} className='mb-3 position-box border shadow'>
                      <div className='position-details'>
                        <p className='position-name'>{position.posName}</p>
                        <p className='position-posts'>{position.numberOfPosts} Positions</p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Conditional rendering for success message */}
      {submissionCarStatus === 'success' && (
        <Alert variant='success' dismissible>
          Form submitted successfully!
        </Alert>
      )}
    </>
  );
}
