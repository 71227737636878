import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { AuthContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

const ServicePage = () => {
  // Mock data for services
  const [services, setServices] = useState([]);

  // State for selected service to delete
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  //const BASE_URL = 'http://localhost:9090'
  const BASE_URL = 'https://mazeflipservices.com:44344'; 

  const {accessToken, setToken} = useContext(AuthContext);
  const navigate = useNavigate()
  const [showAddModal, setShowAddModal] = useState(false);
  const [newService, setNewService] = useState({ serviceName: '', subServiceName: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [allServices, setAllServices] = useState([]);



  ///////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (!accessToken) {
        // If accessToken doesn't exist, redirect to another page
        navigate("/admin-login");
    }

   else { 
    const fetchPoData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/serviceent/list`);
        if (response.status === 200) {
          setServices(response.data);
        } else {
          // Handle unexpected status codes
          console.error('Unexpected status code:', response.status);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error('Error fetching service options:', error);
      }
    };

    fetchPoData();
}
  }, []);
//////////////////////////////////////////////////////////////////////////////////
  // Function to handle deletion of service
  const handleDelete = async () => {
    // Perform the delete API call to your backend
   
   try{ const response = await axios.delete(`${BASE_URL}/admin/delapost/${selectedServiceId}`,{
    headers: {
        Authorization: `Bearer ${accessToken}`
    }
   })
    if (response.status === 204) {
        // If the deletion was successful, remove the service from the list
        setServices(services.filter(service => service.serId !== selectedServiceId));
        // Close the modal
        handleClose();
        // window.location.reload()
      } else {
        // Handle error case, e.g., display error message
        console.error('Error deleting service');
      }}

      catch(error){
        console.error('Error deleting service:', error);
      }
      
  };

  //////////////////////////////////////////////////////////////////////////////
  const handleLogout = async()=>{
    try {

      const response = await axios.post(`${BASE_URL}/admin/logout`,null,
      {
      headers: {
        Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
      }
    })
    if (response.status ===200) {
      
      localStorage.removeItem('accessToken')
      navigate('/admin-login')
    }
   else if (response.status ===404) {
    
    console.log('No token is there')
   } 
      
    } catch (error) {
      console.log('Some error occurred ',error)
    }

    

  }
  ////////////////////////////////////////////////////////////////////////////////

  // Function to handle opening modal and set selected service
  const handleShow = (serId) => {
    setSelectedServiceId(serId);
  };

  // Function to handle closing modal
  const handleClose = () => {
    setSelectedServiceId(null);
  };

  // Function to add a new service
  const handleAddService =async () => {
   try{ const ser = {
        serviceName: newService.serviceName,
        subServiceName: newService.subServiceName
    }
    
   const response = await axios.post(`${BASE_URL}/admin/createpost`,ser,{
    headers: {
        Authorization: `Bearer ${accessToken}`
    }
   })
   if(response.status === 201){
    // Update the services list with the new service returned from the backend
     setServices([...services, response.data]);
    // Reset the newService state and close the modal
    setNewService({ serviceName: '', subServiceName: '' });
    handleCloseAddModal();

   }}
   catch(error){
     console.error("Error occurred ",error)
   }


  };

  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => setShowAddModal(true);

  const handleBEnquiry=()=>{
    navigate('/admin-login/admin-home')
  }

  ///////////////////////////////////////////////////////////////////////////////////
  const filterServices = (searchValue) => {
    if (!searchValue) {
      fetchServices(); // Reset to original services if search is cleared
    } else {
      const filtered = services.filter(service => 
        service.serviceName.toLowerCase().includes(searchValue.toLowerCase()) ||
        service.subServiceName.toLowerCase().includes(searchValue.toLowerCase())
      );
      setServices(filtered);
    }
  };
 
  /////////////////////////////////////////////////////////////////////////////////////////

  const fetchServices = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/serviceent/list`);
      if (response.status === 200) {
        setServices(response.data);
        setAllServices(response.data); // Keep a copy of all services
      } else {
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      console.error('Error fetching service options:', error);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////
 

const handleSearchChange = (e) => {
  const { value } = e.target;
  setSearchTerm(value);
  filterServices(value);
};


  return (
    <>
    <div className='col-sm-6 col-md-3 mb-2 logout-button '> {/* Add margin bottom */}
  <input
    type="text"
    placeholder="Search services..."
    className="form-control"
    value={searchTerm}
    onChange={handleSearchChange}
  />
</div>

    <div className='jumbotron'><h1 className='text-center'>Services</h1></div>
      
      <div className='container'>
        <div className='row'>

        <div className='col-sm-6 col-md-3 mb-2 logout-button '> {/* Add margin bottom */}
        <Button onClick={handleShowAddModal} variant="primary">Add Service</Button>

        </div>

        <div className='col-sm-6 col-md-3 mb-2 logout-button'> {/* Add margin bottom */}
        <button type='button' className='btn text-white' onClick={handleBEnquiry}>Business Enquiry</button>
       </div>
       <div className='col-sm-6 col-md-3 mb-2 logout-button'> {/* Add margin bottom */}
       </div>

        <div className='col-sm-6 col-md-3 mb-2 logout-button '> {/* Add margin bottom */}
        <button type='button' className='btn text-white' onClick={handleLogout}>Logout</button>
        </div>
        </div>
      <Table striped bordered hover className='table-dark'>
        <thead className='thead-dark'>
          <tr>
            <th>Service ID</th>
            <th>Service Name</th>
            <th>Sub-service Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {services.map(service => (
            <tr key={service.serId}>
              <td>{service.serId}</td>
              <td>{service.serviceName}</td>
              <td>{service.subServiceName}</td>
              <td>
                <Button onClick={() => handleShow(service.serId)} variant="danger">Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </div>
      {/* Delete Confirmation Modal */}
      <Modal show={!!selectedServiceId} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the service?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>

       {/* Modal for adding a service */}
       <Modal show={showAddModal} onHide={handleCloseAddModal} >
        <Modal.Header closeButton>
          <Modal.Title>Add Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="serviceName">
              <Form.Label>Service Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter service name"
                value={newService.serviceName}
                onChange={(e) => setNewService({ ...newService, serviceName: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="subServiceName">
              <Form.Label>Sub-service Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter sub-service name"
                value={newService.subServiceName}
                onChange={(e) => setNewService({ ...newService, subServiceName: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>Cancel</Button>
          <Button variant="primary" onClick={handleAddService}>Add</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ServicePage;

