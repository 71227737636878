import React, { useEffect, useState } from 'react'
import cus from '../images/cus.png'
import axios from 'axios';
import './BusinessEnq.css'
import { Alert } from 'react-bootstrap';

const BASE_URL = 'https://mazeflipservices.com:44344'; 
//const BASE_URL = 'http://localhost:9090'

export default function BusinessEnq() {

  // State to manage the selected value
  const [selectedOption, setSelectedOption] = useState('');
  const [serviceOptions, setServiceOptions] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState(null); // Track submission status

  // useEffect(() => {
  //   // Fetch service names and sub-service names from backend
  //   axios.get(`${BASE_URL}/serviceent/list`)
  //     .then(response => {
  //       setServiceOptions(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching service options:', error);
  //     });
  // }, []);


  useEffect(() => {
    const fetchPoData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/serviceent/list`);
        if (response.status === 200) {
          setServiceOptions(response.data);
        } else {
          // Handle unexpected status codes
          console.error('Unexpected status code:', response.status);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error('Error fetching service options:', error);
      }
    };

    fetchPoData();
  }, []);


  // Function to handle changes in the selected value
  const handleChange = (event) => {
    console.log('My name is handleChange')
    console.log('Selected value:', event.target.value);
    setSelectedOption(event.target.value);
    console.log('Selected value:', event.target.value);
  };

  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const requestData = {
      fName: formData.get('fName'),
      lName: formData.get('lName'),
      bEmail: formData.get('bEmail'),
      selectedService: {
        serId: parseInt(selectedOption)
      }, // Assuming selectedOption is a string representing the selected service
      contactNumber: formData.get('contactNumber'),
      address: formData.get('address'),
      message: formData.get('message'),
    };
  console.log('Request data',requestData)
  console.log('Selected option',selectedOption)
    try {
      const response = await axios.post(`${BASE_URL}/userbe/createbe`, requestData);
      console.log('Response:', response.data);

     // Update submission status
     setSubmissionStatus('success');

      // Clear form fields
    setSelectedOption('');
    // Reset other form fields to empty strings
    event.target.reset();

    // Reset submission status after 3 seconds
    setTimeout(() => {
      setSubmissionStatus(null);
    }, 3000);

      // Optionally, you can handle success response here
    } catch (error) {
      console.error('Error creating business enquiry:', error);
      // Optionally, you can handle error response here
    }
  };
  
  console.log('Selected option outside',selectedOption)

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
        <h2 className='text-center fw-bold'>REQUEST FOR A CUSTOMISED QUOTE</h2>
    </div>
    <div >
      <h3 className='text-center fw-bold' >COMPLETE THE BELOW FORM</h3>
    </div><br/><br/>
    <div className='row' >
      <div className='col-1'></div>
      <div className='col-5'>
        <img src={cus} className='img-fluid'  alt='Benquiry'/>
      </div>
      
      <div className='col-5 backform text-white'  style={{borderRadius:'10px'}}><br/>
      
        <form method='post' onSubmit={handleSubmit}  className='form-group'>
          <div className='row'>
            <div className='col-6'>
            <label htmlFor='fName'>First Name*</label>
            <input type='text' id='fName' name='fName' className='form-control' required />
            </div>
            <div className='col-6'>
            <label htmlFor='lName'>Last Name*</label>
            <input type='text' id='lName' name='lName' className='form-control' required />
            </div>
            
          </div>
          <br/>
          <div className='row'>
            <div>
            <label htmlFor='bEmail'>Business Email*</label>
            <input type='email' id='bEmail' name='bEmail' className='form-control' required />
            </div>
          </div>
           <br/>
          <div className='row'>
           <div className='col-6'>
           <label htmlFor='selectedService'>Select Service*</label><br/>
              <select  onChange={handleChange} value={selectedOption} id='selectedService' className='form-control' required >
              <option value="">Please select</option>
              {serviceOptions.map((option, index) => (
                    <option key={index} value={option.serId}>{option.serviceName} - {option.subServiceName}</option>
                  ))}
              </select>
           </div>
           <div className='col-6'>
           <label htmlFor='contactNumber'>Contact Number*</label>
            <input type='text' id='contactNumber' name='contactNumber' className='form-control' required/>
           </div>
          </div>
          <br/>
          <div className='row'>
            <div className=''>
            <label htmlFor='address'>Address*</label>
            <input type='text' id='address' name='address' className='form-control' required/>
            </div>
          </div>
          <div className='row'>
            <div className=''>
            <label htmlFor='message'>Message*</label>
            <input type='text' id='message' name='message' className='form-control' required/>
            </div>
            </div>
            <br/>
            <div className='row justify-content-center'>
            <div className='col-6 offset-3'>
            
            <button type='submit' className='btn btn-primary text-white'>Submit</button>
            </div>
            </div>
             {/* Conditional rendering for success message */}
       {submissionStatus === 'success' && (
            <Alert variant='success' dismissible>
              Form submitted successfully!
            </Alert>
          )}
            
          
        </form>
      </div>
      <div className='col-1'></div>
    
    </div>
      
    </>
  )
}
