import React from 'react'
import { useNavigate } from 'react-router-dom'
import ecom from '../images/ecom.jpg'
import ec from '../images/ec.jpg'

export default function Ecom() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>E-COMMERCE MARKETING</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col-6 animate__animated animate__slideInLeft '>
           
             <img src={ecom} style={{maxWidth:"100%"}}/>
  
    
        </div>
        
        <div className='col-6 animate__animated animate__slideInRight '>
        <img src={ec} style={{maxWidth:"100%"}}/>
        </div>
        
       </div>
       <br/> <br/>
       <div className='row jumbotron'>

       <div className='col-12 animate__animated animate__slideInRight '>
          <h4 className=''>TRUSTED E-COMMERCE SOLUTIONS PARTNER</h4>
          <p className='fw-bold'>We deliver comprehensive e-commerce solutions for renowned brands, earning acclaim for our outstanding services.</p>
          <br/><br/>
          <p className='font-size'>In today's operational landscape, few elements rival the significance of buying and selling. Traditionally, this exchange was confined to interpersonal interactions, but in the digital era, boundaries blur, and commerce transcends physical limitations. E-commerce, the practice of conducting transactions online, has become the cornerstone of modern business operations, revolutionizing the way goods are sold and consumed.</p>
          <p className='font-size'>The impact of e-commerce extends beyond mere transactional convenience; it fundamentally transforms how consumers engage with products. Gone are the days of manual inventory management and subjective product presentations. E-commerce platforms empower customers to navigate vast product catalogs seamlessly, leveraging filters and preferences to tailor their shopping experiences.</p>
          <p className='font-size'>Moreover, in the competitive arena of brand promotion, e-commerce provides unparalleled opportunities to showcase products and connect with a diverse audience. By harnessing the potential of e-commerce, businesses can enhance their marketing strategies, refine product presentations, and ultimately drive higher sales volumes.</p>
          <p className='font-size'>In essence, e-commerce epitomizes the convergence of technology and commerce, offering a dynamic platform for businesses to thrive in the digital age.</p>
          <br/>
          <h4 style={{color:"#7E014D"}}><span className='text-dark'> WHY</span> MAZEFLIP SERVICES</h4>
          <p className='font-size fw-bold'>Choose Mazeflip Services for unparalleled expertise and unwavering dedication in E-commerce, digital marketing, and website design and development. Here's why:</p>
          <p className='font-size'><span className='fw-bold'>Proven Track Record: </span>  With a solid foundation established since 2019, Mazeflip Services has built trusted partnerships with startups, SMEs, and large enterprises. Our track record speaks volumes about our commitment to excellence and delivering tangible results.</p>
          <p className='font-size'><span className='fw-bold'>Comprehensive Solutions: </span> We offer a comprehensive suite of services, including website design and development, e-commerce marketing, social media marketing, SEO, PPC, SEM, content marketing, video advertising, branding solutions, and more. Whatever your online business requirements, we have the expertise to address them effectively.</p>
          <p className='font-size'><span className='fw-bold'>Strategic Approach: </span> At Mazeflip Services, we don't just execute tasks; we craft strategic solutions tailored to your business needs. Our team invests time in deeply understanding your business, identifying key opportunities, and formulating powerful strategies to drive success.</p>
          <p className='font-size'><span className='fw-bold'>Creative Excellence: </span>  Creativity is at the heart of everything we do. We leverage our creative prowess to develop compelling visualizations and captivating content that resonate with your audience and set you apart from the competition.</p>
          <p className='font-size'><span className='fw-bold'>Quality Assurance: </span> We believe in delivering what we promise, without compromising on quality. When you choose Mazeflip Services, you can trust that you're getting top-notch services that meet the highest standards of excellence.</p>
          <p className='font-size'><span className='fw-bold'>Client-Centric Approach: </span> Your success is our priority. We work closely with you every step of the way, ensuring that our solutions align with your goals and objectives. Our client-centric approach ensures that your needs are always at the forefront of our strategies.</p>
          <div>          <p className='font-size'>In summary, Mazeflip Services is your trusted partner for elevating your online presence and driving business growth. With our expertise, strategic approach, and unwavering commitment to quality, we empower businesses to thrive in the digital landscape.</p>
         </div>
          <br/> <div className='color-change'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>  
        </div>
       </div>
      </div>
        
      </>
    )
}
