import React from 'react'
import './AmazonPlus.css'
import { useNavigate } from 'react-router-dom'
import a5 from '../../images/a5.jpg'

export default function AmazonPlus() {
  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>AMAZON A PLUS (ENHANCED BRAND CONTENT)</h2>
    </div>
    <div className='container'>
     <div className='row'>
    
      <div className='col-4 animate__animated animate__slideInLeft '>
         
           <img src={a5} style={{maxWidth:"100%"}} alt='Aenhance' className='img-fluid' />

  
      </div>
      <div className='col-1 '></div>
      <div className='col-7 animate__animated animate__slideInRight '>
        <h5 className=''>Boost Conversions Through Enhanced Amazon Content Solutions.</h5>
        <br/><br/>
        <p className='font-size'>Unlock Your Brand's Potential with Expert Amazon Content Enhancement Services. Harness the power of enhanced Amazon content to surpass industry standards and elevate your brand's presence on the platform. Our tailored approach integrates high-quality images, engaging videos, and compelling product descriptions to optimize conversions and secure top rankings on the Amazon search results page. With Mazeflip Services as your trusted partner, access strategic insights, innovative content strategies, and personalized support to drive sustained business growth. Let us empower your brand with a comprehensive suite of content solutions designed to maximize visibility, enhance customer engagement, and unlock new opportunities for success.</p>
        <br/> <div className='color-change'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>  
      </div>
      
     </div>
    </div>
      
    </>
  )
}
