import React from 'react'
import mgmt from '../images/search.jpg'
import adv from '../images/social.jpg'
import rein from '../images/content.jpg'
import launch from '../images/email.jpg'
import dig from '../images/dig.jpg'
import acam from '../images/acam.jpg'
import fcam from '../images/fcam.jpg'
import { Link, useNavigate } from 'react-router-dom'


export default function DigitalMarket() {

    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
 
    const gridItems = [
        { id: 1, title: 'Search Engine optimisation', image:mgmt, link: '/digital-market/search-engine' },
        { id: 2, title: 'Social Media Marketing', image:adv, link: '/digital-market/social-media' },
        { id: 3, title: 'Content Marketing', image:rein, link: '/digital-market/content-market' },
        { id: 4, title: 'Email Marketing', image: launch, link: '/digital-market/email-market' },
        { id: 5, title: 'Amazon Campaign Management', image: acam, link: '/digital-market/amazon-cam' },
        { id: 6, title: 'Flipkart Campaign Management', image: fcam, link: '/digital-market/flipkart-cam' }
        
        
        
      ];
    
      return (
        <>
        <div className='jumbotron' style={{ paddingTop: '120px' }}>
         <h2 className='text-center fw-bold'>DIGITAL MARKETING</h2>
            </div>

            <div className='container'>
       <div className='row'>
      
        <div className='col-4 animate__animated animate__slideInLeft '>
           
             <img src={dig} style={{maxWidth:"100%"}}/>
  
    
        </div>
        <div className='col-1 '></div>
        <div className='col-7 animate__animated animate__slideInRight '>
          <h5 className=''>Empower Your Business Growth with Strategic Digital Marketing</h5>
          <br/><br/>
          <p className='font-size'>Embark on a journey of unparalleled growth and success with Mazeflip Services, your trusted partner in digital marketing excellence. As a forward-thinking digital marketing company, we specialize in crafting bespoke strategies tailored to propel your business to new heights. Our team of experts combines innovative techniques with cutting-edge technology to deliver results-driven solutions that amplify your online presence, drive qualified leads, and maximize conversions. With a keen focus on ROI and performance metrics, we deploy a holistic approach encompassing SEO, PPC, social media marketing, content creation, email marketing, and more to fuel your business growth. Whether you're a budding startup or an established enterprise, our customized strategies are designed to address your unique challenges and capitalize on emerging opportunities in the digital landscape. Trust Mazeflip Services to be your catalyst for success, and unlock the full potential of your business in today's competitive marketplace.</p>
          <br/> <div className='color-change'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>  
        </div>
       
        
       </div>
      </div>
      <br/><br/> <br/><br/>
        
        <div className="container">
          <div className='row'>
            
          {gridItems.map((item) => (
            <div key={item.id} className="col-6 col-md-4 col-lg-3 grid-item">
              <Link to={item.link} className='text-decoration-none'>
                <img src={item.image} alt={item.title} />
                <div className="title font-size fw-bold animate__animated animate__heartBeat" >{item.title}</div>
              </Link>
            </div>
          ))}
          </div>
        </div>
        </>
      );
}
