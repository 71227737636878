import React from 'react'
import './AmazonRein.css'
import { useNavigate } from 'react-router-dom'
import rein from '../../images/a3.jpg'

export default function AmazonRein() {
  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>AMAZON ACCOUNT REINSTATEMENT</h2>
    </div>
    <div className='container'>
     <div className='row'>
    
      <div className='col-4 animate__animated animate__slideInLeft '>
         
           <img src={rein} style={{maxWidth:"100%"}} alt='arein' className='img-fluid'/>

  
      </div>
      <div className='col-1 '></div>
      <div className='col-7 animate__animated animate__slideInRight '>
        <h5 className=''>Secure Your Success: Proactively Shield Your Account from Suspension Risks.</h5>
        <br/><br/>
        <p className='font-size'>Regain Control: Swiftly Restore Your Suspended Account with Mazeflip Services. Our seasoned experts meticulously analyze the root causes behind your suspension, initiating proactive measures and fostering direct dialogue with Amazon to expedite account reinstatement. Navigate through legal and technical intricacies of Amazon policies effortlessly as our dedicated team manages the entire process on your behalf. From establishing direct communication channels with Amazon to crafting tailored proposals for store optimization, Mazeflip Services ensures a seamless restoration journey. Experience professionalism at its finest with our expert reporting and decisive action-taking, reinstating your online presence with confidence and efficiency.</p>
        <br/> <div className='color-change'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>  
      </div>
      
     </div>
    </div>
      
    </>
  )
}
