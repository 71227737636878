import React from 'react'
import './AmazonAdv.css'
import a2 from '../../images/a2.jpg'
import { useNavigate } from 'react-router-dom'

export default function AmazonAdv() {
  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>AMAZON ADVERTISING</h2>
    </div>
    <div className='container'>
     <div className='row'>
    
      <div className='col-4 animate__animated animate__slideInLeft '>
         
           <img src={a2} style={{maxWidth:"100%"}} alt='Aadv' className='img-fluid'/>

  
      </div>
      <div className='col-1 '></div>
      <div className='col-7 animate__animated animate__slideInRight '>
        <h5 className=''>Boost Sales: Elevate Your Brand with Amazon Marketing Mastery.</h5>
        <br/><br/>
        <p className='font-size'>Empower Your Brand: Unleash the Potential of Amazon Advertising. Our seasoned team specializes in optimizing ad campaigns and product listings, propelling your sales to new heights. Whether you're a new seller or a seasoned pro, our tailored strategies harness the full spectrum of Amazon's advertising formats, driving targeted traffic and boosting conversions. With our expertise, gain a competitive advantage in the bustling Amazon marketplace, leveraging 5 years of proven success in Amazon marketing and SEO solutions.</p>
        <br/> <div className='color-change'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>  
      </div>
      
     </div>
    </div>
      
    </>
  )
}
