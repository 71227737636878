import React from 'react'
import './FlipkartPage.css'
import mgmt from '../images/f1.jpg'
import adv from '../images/f2.jpg'
import rein from'../images/f3.jpg'
import launch from '../images/f4.jpg'
import flip from '../images/flip.png'
import fie from '../images/fie.jpg'

import { Link } from 'react-router-dom'

export default function FlipkartPage() {
    const gridItems = [
        { id: 1, title: 'Flipkart Account Management', image:mgmt, link: '/flipkart/account-management' },
        { id: 2, title: 'Flipkart Advertising', image:adv, link: '/flipkart/advertising' },
        { id: 3, title: 'Flipkart RPD', image:rein, link: '/flipkart/rpd' },
        { id: 4, title: 'Flipkart Account Launch and Registration', image: launch, link: '/flipkart/reg' },
        { id: 5, title: 'Flipkart Image Editing', image: fie, link: '/flipkart/image-edit' }

        
        
      ];
    
      return (
        <>
        <div className='container-fluid' style={{ paddingTop: '120px' }}>
        <div className='animate__animated animate__pulse animate__infinite	infinite text-center '>
         
         <img src={flip} style={{maxWidth:"380px"}} alt='Flipkart' className='img-fluid'/>


    </div>
    </div>
        
        <div className="container">
            <div className='row'>
          {gridItems.map((item) => (
            <div key={item.id} className="col-6 col-md-4 grid-item">
              <Link to={item.link} className='text-decoration-none'>
                <img src={item.image} alt={item.title} className='img-fluid'/>
                <div className="title font-size fw-bold animate__animated animate__heartBeat" >{item.title}</div>
              </Link>
            </div>
          ))}
          </div>
        </div>
        </>
      );
}
