import React from 'react'
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap'
import webDevImg1 from '../images/webDevImg1.jpg'; // Replace with your image path
import webDevImg2 from '../images/webDevImg2.jpg'; // Replace with your image path
import webDevImg3 from '../images/webDevImg3.jpg'; // Replace with your image path
import { useNavigate } from 'react-router-dom';

export default function WebDev() {
  const navigate = useNavigate();
  
  const handleBus =()=>{
    navigate('/buseq')
  }
  return (
    <>
      <Container fluid className="py-5 my-5" style={{ backgroundColor: '#f8f9fa' }}>
      <Container className="text-center mb-5">
          <h1 className="animate__animated animate__fadeIn">Web Development Services</h1>
          <p className="lead animate__animated animate__fadeIn animate__delay-1s">
            Unlock your business potential with our cutting-edge web development solutions. We create dynamic, scalable, and visually stunning websites tailored to meet your unique needs.
          </p>
        </Container>

      <Container>
        <Row className="gy-4">
          <Col md={4}>
            <Card className="h-100 shadow animate__animated animate__fadeIn animate__delay-2s">
              <Card.Img variant="top" src={webDevImg1} alt="Custom Websites" />
              <Card.Body>
                <Card.Title>Custom Websites</Card.Title>
                <Card.Text>
                  From concept to deployment, our team crafts bespoke websites that reflect your brand's identity and cater to your specific audience.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card className="h-100 shadow shadow animate__animated animate__fadeIn animate__delay-3s">
              <Card.Img variant="top" src={webDevImg2} alt="Responsive Design" />
              <Card.Body>
                <Card.Title>Responsive Design</Card.Title>
                <Card.Text>
                  We ensure that your website looks great and functions perfectly on all devices, providing a seamless experience for users on mobile, tablet, and desktop.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card className="h-100 shadow shadow animate__animated animate__fadeIn animate__delay-4s">
              <Card.Img variant="top" src={webDevImg3} alt="E-commerce Solutions" />
              <Card.Body>
                <Card.Title>E-commerce Solutions</Card.Title>
                <Card.Text>
                  Our e-commerce platforms are designed to enhance your online presence, drive sales, and provide a user-friendly shopping experience.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container className="text-center my-5">
        <h2 style={{ color: '#7E014D' }}>Why Choose Us?</h2>
        <Row className="gy-4 mt-4">
          <Col md={6}>
            <Image src={webDevImg1} fluid alt="Professional Web Development" className="rounded shadow" />
          </Col>
          <Col md={6} className="d-flex align-items-center">
            <div>
              <p className="lead">
                We combine creativity, technical expertise, and industry insights to deliver websites that not only look good but also perform exceptionally well. Our focus is on delivering results that drive your business forward.
              </p>
                <Card style={{backgroundColor: '#9FD5DF'}}>
                  <Card.Body>
                    <Card.Title>
                  <ul style={{listStyleType: 'none'}}>
                <li>Tailor-made solutions</li>
                <li>High-performance websites</li>
                <li>SEO and user-friendly designs</li>
                <li>Continuous support and maintenance</li>
              </ul>
                  </Card.Title>
                  </Card.Body>
                  
                </Card>
              
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="text-center my-5">
        <h2 style={{ color: '#7E014D' }}>Our Web Development Process</h2>
        <Row className="gy-4 mt-4">
          <Col md={4}>
            <Card className="h-100 shadow">
              <Card.Body>
                <Card.Title>1. Discovery & Planning</Card.Title>
                <Card.Text>
                  We begin by understanding your goals, audience, and competition to create a strategic plan for your website.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="h-100 shadow">
              <Card.Body>
                <Card.Title>2. Design & Development</Card.Title>
                <Card.Text>
                  Our designers and developers work collaboratively to bring your vision to life with a focus on user experience and functionality.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="h-100 shadow">
              <Card.Body>
                <Card.Title>3. Testing & Launch</Card.Title>
                <Card.Text>
                  We rigorously test your website to ensure it meets all quality standards before launching it to the public.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="text-center my-5">
        <Button variant="primary" size="lg" style={{ backgroundColor: '#7E014D', borderColor: '#7E014D' }} onClick={handleBus}>
          Request a Quote
        </Button>
      </Container>
    </Container>
    </>
  )
}
