import React from 'react'
import './AmazonVideos.css'
import { useNavigate } from 'react-router-dom'
import a8 from '../../images/a8.jpg'


export default function AmazonVideos() {
  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>AMAZON BRAND AND PRODUCT-SPECIFIC VIDEOS</h2>
    </div>
    <div className='container' style={{ paddingTop: '120px' }}>
     <div className='row'>
    
      <div className='col-4 animate__animated animate__slideInLeft '>
         
           <img src={a8} style={{maxWidth:"100%"}} alt='Avid' className='img-fluid'/>

  
      </div>
      <div className='col-1 '></div>
      <div className='col-7 animate__animated animate__slideInRight '>
        <h5 className=''>Engaging and Dynamic Video Production</h5>
        <br/><br/>
        <p className='font-size'>At Mazeflip Services, we recognize the power of visual storytelling in capturing attention and fostering emotional connections. That's why we offer comprehensive video production services tailored specifically for Amazon brand and product promotion. Our team of seasoned creatives is dedicated to understanding your brand's unique identity and objectives, ensuring that every video we produce aligns seamlessly with your vision. From compelling narratives to stunning visuals, we craft videos that resonate with your target audience and drive meaningful engagement. Moreover, our videos are meticulously optimized for mobile viewing, maximizing accessibility and impact across devices. Elevate your brand presence on Amazon with our captivating video content, designed to leave a lasting impression and cultivate a loyal customer following.</p>
        <br/> <div className='color-change'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>  
      </div>
      
     </div>
    </div>
      
    </>
  )
}
