import React from 'react'
import multi from '../images/multi.jpg'
import { useNavigate } from 'react-router-dom'

export default function CampaignMultiple() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>CATALOGING (LISTING) ON MULTIPLE PORTALS</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col-4 animate__animated animate__slideInLeft '>
           
             <img src={multi} style={{maxWidth:"100%"}}/>
  
    
        </div>
        <div className='col-1 '></div>
        <div className='col-7 animate__animated animate__slideInRight '>
          <h5 className=''>Comprehensive Cataloging for Multichannel Listings</h5>
          <br/><br/>
          <p className='font-size'>Mazeflip Services offers comprehensive cataloging solutions for multichannel listings on various e-commerce portals. Our expert team ensures your products are accurately listed across multiple platforms, including Amazon, Flipkart, Nykaa, and more. From product descriptions to images and specifications, we meticulously optimize your listings to maximize visibility and drive sales. With Mazeflip Services, you can expand your reach and streamline your catalog management process across diverse online marketplaces, enhancing your brand's presence and increasing revenue opportunities.</p>
          <br/> <div className='color-change'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>  
        </div>
        
       </div>
      </div>
        
      </>
    )
}
