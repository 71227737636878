import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useContext, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from './AuthContext'

const BASE_URL = 'https://mazeflipservices.com:44344'; 

export default function UpdateBusEnq() {
  const {accessToken, setToken} = useContext(AuthContext);

    const navigate = useNavigate()
    const { bId } = useParams();
    const [selectedOption, setSelectedOption] = useState('');
  const [serviceOptions, setServiceOptions] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState(null); // Track submission status
    const [enquiryData, setEnquiryData] = useState({
        fName: '',
        lName: '',
        bEmail: '',
        contactNumber: '',
        address: '',
        message: '',
        selectedService: { serId: '' } // Initialize with default value
    });


   

////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const fetchEnquiryData = async () => {
          try {
            const response = await axios.get(`${BASE_URL}/userbe/getabq/${bId}`);
            if (response.status ===200) {
                setEnquiryData(response.data);
                
            }
            
          } catch (error) {
            console.error('Error fetching enquiry data:', error);
          }
        };
    
        fetchEnquiryData();
      }, [bId]);

     
      // ////////////////////////////////////////////////////////////////////////

      useEffect(() => {
        // Fetch service names and sub-service names from backend
        axios.get(`${BASE_URL}/serviceent/list`)
          .then(response => {
            setServiceOptions(response.data);
          })
          .catch(error => {
            console.error('Error fetching service options:', error);
          });
      }, []);


      const handleSubmit = async (event) => {
        event.preventDefault();
       
        try {
          const response = await axios.patch(`${BASE_URL}/admin/update/${bId}`, enquiryData,{
            headers: {
              Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
            }
          });
          if (response.status === 200) {
              // Update submission status
              setSubmissionStatus('success');
  
             
  
              // Reset submission status after 3 seconds
              setTimeout(() => {
                  setSubmissionStatus(null);
                  navigate('/admin-login/admin-home')
              }, 3000);
  
              // Optionally, you can navigate to another page or handle success response here
          }
      } catch (error) {
          console.error('Error updating business enquiry:', error);
          // Optionally, handle error response here
      }
      };
      
   //////////////////////////////////////////////////////////////////////////// 
   const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "selectedService") {
        setEnquiryData({
            ...enquiryData,
            selectedService: {
                serId: value
            }
        });
    } else {
        setEnquiryData({
            ...enquiryData,
            [name]: value
        });
    }
};

      



  return (
    <>
    <div className='container border'>
      <div className='container-fluid' ><h3 className='text-center'>Update Business Enquiry</h3></div>
      
      <div className='container-fluid' style={{maxWidth:"50%"}}>
      <form method='patch' onSubmit={handleSubmit}  className='form-group'>
        <div className='row'>
           <div className='col'>
           <label htmlFor='bId'>ID</label>
            <input type='number' id='bId' name='bId' value={enquiryData.bId || ''}  className='form-control' disabled />
           </div>
        </div>
          <div className='row'>
            <div className='col-6'>
            <label htmlFor='fName'>First Name*</label>
            <input type='text' id='fName' name='fName' value={enquiryData.fName || ''}  onChange={handleChange} className='form-control' required />
            </div>
            <div className='col-6'>
            <label htmlFor='lName'>Last Name*</label>
            <input type='text' id='lName' name='lName' value={enquiryData.lName || ''}  onChange={handleChange} className='form-control' required />
            </div>
            
          </div>
          <br/>
          <div className='row'>
            <div>
            <label htmlFor='bEmail'>Business Email*</label>
            <input type='email' id='bEmail' name='bEmail' value={enquiryData.bEmail || ''} onChange={handleChange} className='form-control' required />
            </div>
          </div>
           <br/>
          <div className='row'>
           <div className='col-6'>
           <label htmlFor='selectedService'>Select Service*</label><br/>
              <select onChange={handleChange}   id='selectedService' name='selectedService' value={enquiryData.selectedService.serId || ''} className='form-control' required >
              <option value="">Please select</option>
              {serviceOptions.map((option, index) => (
                    <option key={index} value={option.serId}>{option.serviceName} - {option.subServiceName}</option>
                  ))}
              </select>
           </div>
           <div className='col-6'>
           <label htmlFor='contactNumber'>Contact Number*</label>
            <input type='text' id='contactNumber'  onChange={handleChange} name='contactNumber' value={enquiryData.contactNumber || ''} className='form-control' required/>
           </div>
          </div>
          <br/>
          <div className='row'>
            <div className=''>
            <label htmlFor='address'>Address*</label>
            <input type='text' id='address'  onChange={handleChange} name='address' value={enquiryData.address || ''} className='form-control' required/>
            </div>
          </div>
          <div className='row'>
            <div className=''>
            <label htmlFor='message'>Message*</label>
            <input type='text' id='message'  onChange={handleChange} value={enquiryData.message || ''} name='message' className='form-control' required/>
            </div>
            </div>
            <br/>
            <div className='row justify-content-center'>
            <div className='col-6 offset-3'>
            
            <button type='submit' className='btn btn-primary text-white'>Submit</button>
            </div>
            </div>
             {/* Conditional rendering for success message */}
       {submissionStatus === 'success' && (
            <Alert variant='success' dismissible>
              Form updated successfully!
            </Alert>
          )}
            
          
        </form>
      </div>
    </div>
      
    </>
  )
}
