import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';

export default function BusinessEnquiryItem({ enquiry, onDelete }) {
    const [showModal, setShowModal] = useState(false);

    const handleDeleteConfirmation = () => {
      setShowModal(true);
    };
  
    const handleDelete = () => {
      onDelete(enquiry.bId); // Call the onDelete function with the ID of the enquiry
      setShowModal(false);
    };

    const handleEdit = () => {
        // Logic for editing the enquiry
      };
  
    return (
        <>
      
        {/* Render enquiry details */}
        {/* <td><button type='button' onClick={handleEdit} className='btn btn-primary text-light'>Edit</button></td> */}
        {/* Other table cells */}
        <td>
          <button onClick={handleDeleteConfirmation} type='button' className='btn remcol text-light'>Remove</button>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this enquiry?
            </Modal.Body>
            <Modal.Footer>
              
              <Button variant="primary" onClick={handleDelete}>
                Yes
              </Button>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </td>
      
      </>
    );
  };

