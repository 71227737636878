import React from 'react'
import { Card } from 'react-bootstrap'
import './Contacts.css'

export default function Contacts() {
  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
        <h2 className='text-center'>
          Contact <span style={{color:"#7E014D"}}>Us</span>
          </h2>
    </div>
    <div className='container-fluid'>
    <h4 className='text-center'>
    <span style={{color:"#4285F4"}} >G</span>
<span style={{color:"#EA4335"}}>o</span>
<span style={{color:"#FBBC05"}}>o</span>
<span style={{color:"#4285F4"}}>g</span>
<span style={{color:"#34A853"}}>l</span>
<span style={{color:"#EA4335"}}>e </span>
         Map To Reach Us</h4><br/>
    <div className='conainer-fluid row'>
        <div className='col-1'></div>
        <div className='col-10'>
        <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3597.4826250731776!2d85.05401359999999!3d25.622102899999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed57bd196b078f%3A0xdca81888026f4000!2sMadhu%20Villa!5e0!3m2!1sen!2sin!4v1708439678399!5m2!1sen!2sin"
      width="100%"
      height="700"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
        </div>
        <div className='col-1'></div>
   
    </div>
    <br/><br/> <br/>
    <div  className='container-fluid'>
   
    <h2 className='text-center fw-bold'>CONTACT <span style={{color:"#7E014D"}}>DETAILS</span> </h2>
    <br/><br/>
    <div className='row font-size'>
    <div className='col-4 '>
       
    </div>
    
    <div className='col-4'>
    <div className="contact-details">
      <div className="detail-item"><div> <i class="bi bi-telephone-fill" ></i></div>
     
      <span className='fw-bold'>Business Enquiry:</span>
      <span><a href="tel:+918789541680" className='text-decoration-none'  ><span style={{color:"#7E014D"}}>+918789541680</span> </a></span>
      </div>
      <br/>
      <div className="detail-item "><div><i class="bi bi-envelope-at-fill" ></i></div>
      
      <span className='fw-bold'>Email:</span>
      <span> <a href='mailto:contact@mazeflipservices.com' className="text-decoration-none"><span style={{color:"#7E014D"}} > contact@mazeflipservices.com</span></a></span>
      </div>
      <div className="detail-item"><div><i class="bi bi-geo-alt-fill" ></i></div>
      
        <span className='fw-bold'>Address:</span>
        <span style={{color:"#7E014D", fontSize:18}}><br/><br/> Mazeflip Services, Ward No-20,Sultanpur, East Matth,PO Danapur Cantt,Patna, Bihar,PIN:801503</span>
      </div>
      <br/>
      <div className="detail-item "><div><i class="bi bi-alarm-fill"></i></div>
      
        <span className='fw-bold'>Timing:</span>
       
        <span style={{color:"#7E014D", fontSize:18}}>Mon to Sat (10 am to 7 pm)</span>
       
      </div>
    </div>

    </div>
    <div className='col-4'>
       
    </div>
    </div>
    
    </div>
   
    </div>
      
    </>
  )
}
