import React from 'react'
import mgmt from '../../images/a1.jpg'
import './AmazonAccmgmt.css'
import { useNavigate } from 'react-router-dom'


export default function AmazonAccMgmt() {

  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>AMAZON ACCOUNT MANAGEMENT</h2>
    </div>
    <div className='container'>
     <div className='row'>
    
      <div className='col-4 animate__animated animate__slideInLeft '>
         
           <img src={mgmt} style={{maxWidth:"100%"}} alt='amgmt' className='img-fluid'/>

  
      </div>
      <div className='col-1 '></div>
      <div className='col-7 animate__animated animate__slideInRight '>
        <h5 className=''>Premium Account Management Solutions by Industry Experts</h5>
        <br/><br/>
        <p className='font-size'>To propel your business to new heights, maintaining a thriving seller account on the Amazon marketplace is paramount. Our comprehensive Amazon Account Management Service is designed to elevate your business presence on Amazon. Led by a team of seasoned Amazon consultants, we provide tailored solutions to drive your store towards unprecedented success. Specializing in account health management, strategic deals and promotions, targeted advertising, dispute resolution, meticulous inventory management, seamless new product listing and modification, and adept handling of return claims, our services are geared towards optimizing product positioning and enhancing seller central backend operations. With a keen focus on addressing day-to-day account issues and resolving seller queries, we offer results-driven Amazon account management services tailored for markets including India, USA, UAE, Canada, Australia, and beyond. Connect with our experts today to unlock the full potential of your Amazon Seller Account, boost ROI, maximize keyword ranking, enhance listing visibility, drive conversions, and skyrocket sales.</p>
        <br/> <div className='color-change'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>  
      </div>
      
     </div>
    </div>
      
    </>
  )
}
