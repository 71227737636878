import React, { useState } from 'react'
import { AuthContext } from './AuthContext';
import Cookies from 'js-cookie';

export default function AuthProvider({children}) {
    //const [accessToken, setToken] = useState(localStorage.getItem('accessToken') || null);
    const [accessToken, setToken] = useState(Cookies.get('accessToken') || null);

    return (
      <AuthContext.Provider value={{ accessToken, setToken }}>
        {children}
      </AuthContext.Provider>
    );
  
}
