import React from 'react';
import nnn from '../images/nnn.jpg';
import prd from '../images/prd.jpeg';

export default function Aboutus() {
  return (
    <>
      <div style={{ paddingTop: '120px' }} >
        <div className='jumbotron'>
          <h2 className='text-center'>
            <span className=''>ABOUT </span>
            <span className='animate__animated animate__heartBeat' style={{ color: '#7E014D', display: 'inline-block' }}>MAZEFLIP SERVICES</span>
          </h2>
        </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-5'>
            <img src={nnn} className='img-fluid animate__animated animate__slideInLeft' alt='About Us' />
          </div>
          <div className='col-md-7'>
            <div className='text-center text-md-start animate__animated animate__slideInRight'>
              <p className='' style={{ fontSize: 15, color: "#7E014D" }}>
                Mazeflip Services, an E-commerce, digital marketing, and website design and development service provider based in Patna. We have set a benchmark and mastered our skills. We are diligently assisting with website design and development, e-commerce marketing, social media marketing, SEO, PPC, SEM, content marketing, video advertising, branding solutions, and more.
              </p>
              <p className='' style={{ fontSize: 15, color: "#7E014D" }}>Since 2019, we’ve built trusted partnerships with an extraordinary roster of startups, SMEs, and large enterprises and helped them tell business stories, move hearts, get recognized, and drive results. We devour time in intensely comprehending the business, recognizing key speaking points and ultimately creating emphatic business plans and strategies.
              </p>
              <p className='' style={{ fontSize: 15, color: "#7E014D" }}>In brief, we have solutions for all your online business requirements. We believe in delivering what we promise, and we never compromise on quality. We strategically formulated digital marketing solutions that will help businesses stay ahead of the competition. Our focus is on deploying our creative excellence in creating powerful core visualization. We expend time to intensely understand the business, identify key speaking points, and finally create powerful business ideation and strategies. We formulate strategies to make digital marketing the best route to drive business for our clients.
              </p>
            </div>
          </div>
        </div>
        </div>

        <div className='jumbotron'>
          <h2 className='text-center fw-bold'>
            <span>ABOUT THE </span>
            <span className='animate__animated animate__heartBeat' style={{ color: '#7E014D', display: 'inline-block' }}>DIRECTOR</span></h2>
        </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-5 order-md-2'>
            <img src={prd} className='img-fluid animate__animated animate__slideInRight' alt='Director' />
          </div>
          <div className='col-md-7 order-md-1'>
            <div className='text-center text-md-start animate__animated animate__slideInLeft'>
              <br />
              <h3 className='text-danger'>The Director</h3>
              <p className='' style={{ fontSize: 15, color: "#7E014D" }}>Meet Mr. Pradeep Kumar, Our Director. With a wealth of experience in [industry], Mr. Kumar is a visionary leader at the helm. Committed to excellence, innovation, and fostering a collaborative environment, he guides our organization towards sustained success. Passionate about [specific goals], Mr. Kumar believes in the power of innovation and open communication. Beyond the boardroom, he enjoys [hobbies or interests]. Connect with our Director as we navigate the future with passion and integrity. Welcome to the journey led by Mr. Pradeep Kumar, shaping success at every step.</p>
              <p className='' style={{ fontSize: 15, color: "#7E014D" }}>Professional Journey: My journey in the corporate world has been nothing short of a thrilling adventure. With a solid educational foundation in [relevant field], I embarked on my professional career with a determination to make a meaningful impact. Over the years, I have had the privilege of working in diverse roles and capacities, each contributing to my growth as a leader.</p>
              <p className='' style={{ fontSize: 15, color: "#7E014D" }}>Leadership Philosophy: At the core of my leadership philosophy is a commitment to excellence, innovation, and people. I believe in fostering a collaborative and inclusive environment where every team member feels valued and empowered. Leadership, in my view, is not about authority but about inspiring and enabling others to unleash their full potential.</p>
              <p className='' style={{ fontSize: 15, color: "#7E014D" }}>Connect with Me: I value open communication and collaboration. Whether you're a member of our esteemed team, a client, or a partner, I encourage you to connect with me. My door is always open to discussions, feedback, and ideas. Together, we can build a thriving community within our organization and make a positive impact on the industry.</p>
            </div>
          </div>
        </div>
        </div>

      </div>
    </>
  );
}
