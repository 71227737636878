import axios from 'axios'

import Cookies from 'js-cookie'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../AuthContext'

 const BASE_URL = 'https://mazeflipservices.com:44344'; 
//const BASE_URL = 'http://localhost:9090'; 

export default function AdminDepartments() {

    const {accessToken, setToken} = useContext(AuthContext);

    const navigate = useNavigate()
    const[allDepts, setAllDepts] = useState([])
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [departmentName, setDepartmentName] = useState('');
    const [showAddPosModal, setShowAddPosModal] = useState(false);
    const [selectedDeptId, setSelectedDeptId] = useState(null); // State to store the selected department ID
    const[posName, setPosName] =useState('')
    const[numberOfPosts, setNumberOfPosts] =useState('')


   

 ////////////////////////////////////////////////////////////////////////////

 useEffect(() => {
    if (accessToken) {
      const handleAllDepartments = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/admin/alldepts`, {
            headers: {
              Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
            }
          });
          setAllDepts(response.data);
          console.log('All Departments:', response.data);
        } catch (error) {
          console.error('Error fetching business enquiries:', error);
        }
      };
      handleAllDepartments();
    }
  }, []);

//////////////////////////////////////////////////////////////////////////////////

const handleDeletePosition = async (posId) => {
    // Logic to delete the position with the given ID from the department
    try {
        const response = await axios.delete(`${BASE_URL}/admin/departments/deletepos/${posId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
            }
        });
        
        if (response.status === 204) {
            window.location.reload(); // Reload the page
        
        }
    } catch (error) {
        console.error('Error deleting position:', error);
    }
};

////////////////////////////////////////////////////////////////////////////////////

const handleDeleteDepartment = async (dId) => {
    // Logic to delete the department with the given ID
    try {
        const response = await axios.delete(`${BASE_URL}/admin/departments/deletedept/${dId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
            }
        });

        if (response.status === 204) {
            window.location.reload(); // Reload the page
        
        }
       

    } catch (error) {
        console.error('Error deleting department:', error);
    }
};

const handleAddDepartment = () => {
    setShowModal(true);
};

const handleCloseModal = () => {
    setShowModal(false);
    setShowAddPosModal(false)
    setSelectedDeptId(null); // Reset selected department ID when closing modal

};

const handleAddPosition = (deptId) => { // Modify to accept deptId
    setSelectedDeptId(deptId); // Set the selected department ID
    setShowAddPosModal(true);
};

const handleChange = (event) => {
    setDepartmentName(event.target.value);
};

 const handleDepartmentAdd = async (event) => {
    event.preventDefault();
   if(accessToken){
    


    const deptData ={
        dName: departmentName
    }

     // Logic to delete the department with the given ID
     try {
        const response = await axios.post(`${BASE_URL}/admin/departments/adddep`,deptData ,{
            headers: {
                Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
            }
        });

        if (response.status === 201) {
            window.location.reload(); // Reload the page
        
        }
       

    } catch (error) {
        console.error('Error Adding department:', error);
    }
   }
};
/////////////////////////////////////////////////////////////////////////////////

const handlePosChange =(event, setState)=>{
    setState(event.target.value)
}


const handlePositionAdd = async (event) => {
    event.preventDefault();
    if (accessToken && selectedDeptId) { // Check if a department is selected
        const formData = new FormData(event.target);
        const newPosName = formData.get('posName');
        const newNumberOfPosts = formData.get('numberOfPosts');
        console.log('New Position Name:', newPosName);
        console.log('Number of Posts:', newNumberOfPosts);
        setPosName(newPosName); // Update posName state with the new position name
        setNumberOfPosts(newNumberOfPosts); // Update numberOfPosts state with the new number of posts
        const posData = {
            posName: newPosName,
            numberOfPosts: newNumberOfPosts
        }
        try {
            const response = await axios.post(`${BASE_URL}/admin/${selectedDeptId}/addpost`, posData, { // Include selectedDeptId in the URL
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response.status === 201) {
               // Update the state with the new position data
               const newPosition = response.data; // Assuming response.data contains the new position object
               setAllDepts(prevDepts => {
                   // Find the department that the position belongs to
                   const updatedDepts = prevDepts.map(dept => {
                       if (dept.dId === selectedDeptId) {
                           // Add the new position to the positions array of the department
                           return {
                               ...dept,
                               positions: [...dept.positions, newPosition]
                           };
                       }
                       return dept;
                   });
                   return updatedDepts;
               });
               setShowAddPosModal(false); // Close the modal

            }
        } catch (error) {
            console.error('Error Adding position:', error);
        }
    }
};

///////////////////////////////////////////////////////////////////////////////////

const handleLogout = async()=>{
    try {

      const response = await axios.post(`${BASE_URL}/admin/logout`,null,
      {
      headers: {
        Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
      }
    })
    if (response.status ===200) {
      
        localStorage.removeItem('accessToken')
      navigate('/admin-login')
    }
   else if (response.status ===404) {
    
    console.log('No token is there')
   } 
      
    } catch (error) {
      console.log('Some error occurred ',error)
    }

  }

  ///////////////////////////////////////////////////////////////////////////////

  const handleBEnquiry=()=>{
    navigate('/admin-login/admin-home')
  }

  //////////////////////////////////////////////////////////////////////////////

  const handleCareerPage=()=>{
    navigate('/admin-login/admin-career')
  }

  ///////////////////////////////////////////////////////////////////////////////

  return (
    <>
            <div className='jumbotron p-3'>
                <h2 className='text-center'>ADMIN</h2>
            </div>
            <div className='container text-center'>
            <div className='row'>
    <div className='col-sm-6 col-md-3 mb-2 '> {/* Adjust column sizes based on screen size */}
        <button className="btn btn-primary" onClick={handleAddDepartment}>Add Department</button>
    </div>
    <div className='col-sm-6 col-md-3 logout-button mb-2'> {/* Adjust column sizes based on screen size */}
        <button type='button' className='btn text-white' onClick={handleBEnquiry}>Business Enquiry</button>
    </div>
    <div className='col-sm-6 col-md-3 logout-button mb-2'> {/* Adjust column sizes based on screen size */}
        <button type='button' className='btn text-white' onClick={handleCareerPage}>Career Page</button>
    </div>
    <div className='col-sm-6 col-md-3 logout-button mb-2'> {/* Adjust column sizes based on screen size */}
        <button type='button' className='btn text-white' onClick={handleLogout}>Logout</button>
    </div>
</div>

               
                <br/>

                {allDepts.map(dept => (
                    <div key={dept.dId} className='container border border-dark p-3'>
                        <h3>{dept.dName}</h3>
                        <ul>
                            {dept.positions.map(pos => (
                                <li key={pos.posId}>
                                    {pos.posName}
                                    <button onClick={() => handleDeletePosition(pos.posId)}>X</button>
                                </li>
                            ))}
                        </ul>
                        <button className='btn btn-primary'  onClick={()=>handleAddPosition(dept.dId)}>Add Positions</button>
                        <div className='logout-button'>
                        <button className='btn btn-primary'  onClick={() => handleDeleteDepartment(dept.dId)}>Delete Department</button>

                        </div>
                    </div>
                ))}
            </div>

           {/* Modal for adding a department */}
           <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Department</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Add department form or content here */}
                    <form className='form-group' method='post' onSubmit={handleDepartmentAdd} >
                    <input type='text' name='dName' id='dName' placeholder='Enter new department name' value={departmentName} 
                    onChange={handleChange} className='form-control'/>
                    <div className="modal-footer" >
                    <button className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                    <button className="btn btn-primary" type='submit'>Save Department</button>
                    </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                   
                </Modal.Footer>
            </Modal>

            {/* Modal for adding a position */}
            <Modal show={showAddPosModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Position</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className='form-group' method='post' onSubmit={handlePositionAdd} >
                        <input type='text' name='posName' id='posName' placeholder='Enter new position name'
                            value={posName} onChange={(e) => handlePosChange(e, setPosName)}  className='form-control' />
                             <input type='text' name='numberOfPosts' id='numberOfPosts' placeholder='Enter number of vacant posts'
                            value={numberOfPosts} onChange={(e) => handlePosChange(e, setNumberOfPosts)} className='form-control' />
                            <div className='modal-footer'>
                            <button className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                            <button className="btn btn-primary" type='submit'>Save Position</button>
                            </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                   
                </Modal.Footer>
            </Modal>
        </>
  )
}
