import React from 'react'
import { useNavigate } from 'react-router-dom'
import acam from '../../images/acam.jpg'

export default function AmazonCamp() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>AMAZON CAMPAIGN MANAGEMENT</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col-4 animate__animated animate__slideInLeft '>
           
             <img src={acam} style={{maxWidth:"100%"}} className='img-fluid' alt='Acamp'/>
  
    
        </div>
        <div className='col-1 '></div>
        <div className='col-7 animate__animated animate__slideInRight '>
          <h5 className=''>Optimize Your Amazon Campaigns with Mazeflip Services</h5>
          <br/><br/>
          <p className='font-size'>Mazeflip Services specializes in comprehensive Amazon Campaign Management, offering tailored strategies to elevate product visibility and drive sales on the Amazon platform. With our expertise, we meticulously analyze market trends, conduct thorough keyword research, and craft compelling ad creatives to ensure maximum impact. Our dedicated team at Mazeflip Services meticulously monitors campaign performance, adjusting bids, refining targeting parameters, and optimizing ad content to deliver optimal results. By harnessing the power of data-driven insights and employing proven strategies, Mazeflip Services empowers sellers and brands to achieve their advertising goals on Amazon. Partner with Mazeflip Services for unparalleled Amazon Campaign Management solutions tailored to your specific needs and objectives.</p>
          <br/> <div className='color-change'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>  
        </div>
        
       </div>
      </div>
        
      </>
    )
}
