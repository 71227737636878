import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { ReactComponent as UserIcon } from '../icons/user-regular.svg';
import { ReactComponent as LoginIcon } from '../icons/right-to-bracket-solid.svg';
import { ReactComponent as CartIcon } from '../icons/cart-shopping-solid.svg';
import { ReactComponent as SearchIcon } from '../icons/magnifying-glass-solid.svg';
import './Navbar.css';
import UserLogin from './UserLogin';
import { useNavigate,Routes,Route, Link} from 'react-router-dom';
import UserRegister from './UserRegister';
import Cookies from 'js-cookie';
import maze from '../images/maze.jpg'
import { jwtDecode } from 'jwt-decode';
import { AuthContext } from './AuthContext';
import { Container, Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap';





const MyNavbar = ({ loggedIn, setLoggedIn, showLoginForm, setShowLoginForm,sendDataToParent}) => {
  const history = useNavigate();
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState(null); // State variable to store the username
  const {accessToken, setToken} = useContext(AuthContext);
  const navigate=useNavigate()
   // State variable to manage dropdown visibility
   const [servicesHover, setServicesHover] = useState(false);
   const [ecommerceHover, setEcommerceHover] = useState(false);
   const [digitalHover, setDigitalHover] = useState(false);
   const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

 
 

  // useEffect(()=>{
  //   if(accessToken){
  //     const decodedToken = jwtDecode(accessToken);
    
   
  //     sendDataToParent(decodedToken.sub);
    
  //   }
    
  // }, [])

  const textStyle = {
    fontFamily: 'Georgia', // Specify the font family
    fontSize: '20px', // Optional: Adjust the font size
    fontWeight: 'normal' // Optional: Adjust the font weight
    
  };

  const handleHomeButtonClick = (event)=>{
           
    if (event && event.target) {
      event.target.blur();
    }
  }

  const handleHomeClick = ()=>{
       navigate('/')
  }
  const handleAboutClick = ()=>{
    navigate('/about')
}

const handleBusClick = () =>{
  navigate('/buseq')
}
const handleCarClick = () =>{
  navigate('/careerpg')
}

const handleConClick = () =>{
  navigate('/contacts')
}


const handleServicesHover = () => {
  setServicesHover(true);
}

const handleServicesLeave = () => {
  setServicesHover(false);
}

const handleEcommerceHover = () => {
  setEcommerceHover(true);
};

const handleEcommerceLeave = () => {
  setEcommerceHover(false);
};

const handleDigitalHover = () => {
  setDigitalHover(true);
};

const handleDigitalLeave = () => {
  setDigitalHover(false);
};

const handleAmazon = ()=>{
  navigate('/amazon')
}

const handleAmazonAccMgmt = ()=>{
  navigate('/amazon/account-management')
}

const handleAmazonAd = ()=>{
  navigate('/amazon/advertising')
}
const handleAmazonRein = ()=>{
  navigate('/amazon/reinstatement')
}
const handleAmazonReg = ()=>{
  navigate('/amazon/register')
}
const handleAmazonPlus = ()=>{
  navigate('/amazon/aplus')
}
const handleAmazonStore = ()=>{
  navigate('/amazon/store')
}

const handleAmazonBrand = ()=>{
  navigate('/amazon/brand')
}
const handleAmazonList = ()=>{
  navigate('/amazon/listing')
}

const handleAmazonImageEdit = ()=>{
  navigate('/amazon/image-edit')
}





const handleFlipkart = ()=>{
  navigate('/flipkart')
}

const handleFlipkartAccMgmt = ()=>{
  navigate('/flipkart/account-management')
}

const handleFlipkartAd = ()=>{
  navigate('/flipkart/advertising')
}

const handleFlipkartRpd = ()=>{
  navigate('/flipkart/rpd')
}

const handleFlipkartReg = ()=>{
  navigate('/flipkart/reg')
}

const handleFlipkartImageEdit = ()=>{
  navigate('/flipkart/image-edit')
}

const handleEcomClick = ()=>{
  navigate('/ecommerce')
}

const handleNykaa = ()=>{
  navigate('/nykaa')
}
const handleJio = ()=>{
  navigate('/jiomart')
}

const handleDigitalMarket = ()=>{
  navigate('/digital-market')
}

const handleProductPhoto = ()=>{
  navigate('/product-photo')
}

const handleSearchEngine = ()=>{
  navigate('/digital-market/search-engine')
}

const handleSocialMedia = ()=>{
  navigate('/digital-market/social-media')
}

const handleContentMarket = ()=>{
  navigate('/digital-market/content-market')
}

const handleEmailMarket = ()=>{
  navigate('/digital-market/email-market')
}

const handleAmazonCampaign =()=>{

  navigate('/digital-market/amazon-cam')
}

const handleFlipkartCampaign =()=>{

  navigate('/digital-market/flipkart-cam')
}

const handleImageEdit = ()=>{
  navigate('/image-editing')
}

const handleMultiPort = ()=>{
  navigate('/multi-portals')
}



const handleMeesho = ()=>{
  navigate('/meesho')
}
const handlePerWeb = ()=>{
  navigate('/perweb')
}
const handleAjio = ()=>{
  navigate('/ajio')
}
const handleShopsy = ()=>{
  navigate('/shopsy')
}

const handleMyntra = ()=>{
  navigate('/myntra')
}

const handleEventsPage=()=>{
  navigate('/events')
}

const handleAllService=()=>{
  navigate('/all-services')
}
const handleWebDevClick =()=>{
  navigate('/web-dev')
}

  ///////////////////////////////////////////////////////////

  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);

  const handleToggleOffcanvas = () => setShowOffcanvas((prev) => !prev);


  return (
    <>
    
    {/* Conditional rendering of navbar buttons based on the state of showOffcanvas */}
    {!showOffcanvas && (
      <Navbar expand="lg" bg="light" fixed="top">
        <Container >
          <Navbar.Brand href="/">
            <img src={maze} alt="Logo" style={{ maxWidth: "100px" }} className='logo'/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarTogglerDemo02" onClick={handleToggleOffcanvas} />
          <Navbar.Collapse id="navbarTogglerDemo02">
          
            <Nav className="me-auto">
              {/* Paste your old <ul> list of menus here */}
              <ul className="navbar-nav justify-content-center">
      

      <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleHomeClick();}}  className='btn btn-gradient btn-sm fw-bold mx-3' style={{backgroundColor:"white",color:"#7E014D", fontSize:12}}>Home</button></li>
      <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleAboutClick();}}  className='btn btn-gradient btn-sm fw-bold mx-3' style={{backgroundColor:"white",color:"#7E014D", fontSize:12}}>About us</button></li>
      <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleAmazon();}}  className='btn btn-gradient btn-sm fw-bold mx-3' style={{backgroundColor:"white",color:"#7E014D", fontSize:12}}>Amazon</button></li>
      <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleFlipkart();}}  className='btn btn-gradient btn-sm fw-bold mx-3' style={{backgroundColor:"white",color:"#7E014D", fontSize:12}}>Flipkart</button></li>

      <li className="navbar__category nav-item">
    <div className='dropdown' onMouseEnter={handleServicesHover} onMouseLeave={handleServicesLeave}>
      <button type='button' className='btn btn-gradient btn-sm fw-bold dropdown-toggle mx-3' style={{backgroundColor:"white",color:"#7E014D", fontSize:12}} onClick={handleAllService}>
        Services
      </button>
      <ul className={`dropdown-menu ${servicesHover ? 'show' : ''}`} style={{  left: "0" }}>
        <li className="navbar__category">
          <div className='dropdown' onMouseEnter={handleEcommerceHover} onMouseLeave={handleEcommerceLeave}>
            <button type='button' className='btn btn-gradient btn-sm fw-bold  dropdown-toggle' onClick={handleEcomClick} style={{backgroundColor:"white",color:"#7E014D"}}>
              E-commerce Marketing
            </button>
            <ul className={`dropdown-menu ${ecommerceHover ? 'show' : ''}`} style={{  left: "100%", top: "0" }}>
            <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}}  onClick={handleAmazon}>
              Amazon <i className="bi bi-caret-down"></i>
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonAccMgmt}>
              Account Management
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonAd}>
              Advertising
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonRein}>
              Account Reinstatement
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonReg}>
             Account Launch and Registration
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonPlus}>
              A Plus(Enhanced Brand Content)
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonStore}>
              Storefront Creation
            </button>
      
          </div>
        </li>
        
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonBrand}>
              Brand and Product-specific videos
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonList}>
              Product Listing
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonImageEdit}>
              Image Editing
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkart}>
              Flipkart <i className="bi bi-caret-down"></i>
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkartAccMgmt}>
              Flipkart Account Management
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkartAd}>
              Flipkart Advertising
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkartRpd}>
              Flipkart RPD
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkartReg}>
              Flipkart Account Launch and Registration
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkartImageEdit}>
              Flipkart Image Editing
            </button>
      
          </div>
        </li>
             
            </ul>
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleMultiPort}>
            Cataloging on Multiple Portals
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div className='dropdown' onMouseEnter={handleDigitalHover} onMouseLeave={handleDigitalLeave}>
            <button type='button' className='btn btn-gradient btn-sm fw-bold dropdown-toggle' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleDigitalMarket}>
              Digital Marketing
            </button>
            <ul className={`dropdown-menu ${digitalHover ? 'show' : ''}`} style={{  left: "100%", top: "0" }}>
  
            <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonCampaign}>
              Amazon Campaign Management
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkartCampaign}>
              Flipkart Campaign Management
            </button>
      
          </div>
        </li>
  
            <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleSearchEngine}>
              Search Engine Optimisation
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleSocialMedia}>
              Social Media Marketing
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleContentMarket}>
              Content Marketing
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleEmailMarket}>
              Email Marketing
            </button>
      
          </div>
        </li>
        
              
            </ul>
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleImageEdit}>
            Image Editing
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handlePerWeb}>
            Personal Website
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleMyntra}>
            Myntra
            </button>
      
          </div>
        </li>
  
        
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleJio}>
            Jio Mart
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleNykaa}>
            Nykaa
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleMeesho}>
            Meesho
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleShopsy}>
            Shopsy
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm fw-bold' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAjio}>
            Ajio
            </button>
      
          </div>
        </li>
  
        
  
        
        
      </ul>
    </div>
  </li>
  
  
  
                  <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleBusClick()}}  className='btn btn-gradient btn-sm fw-bold mx-3' style={{backgroundColor:"white",color:"#7E014D", fontSize:12}}>Business Enquiry</button></li>
                  <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleWebDevClick()}}  className='btn btn-gradient btn-sm fw-bold  mx-3' style={{backgroundColor:"white",color:"#7E014D", fontSize:12}}>Web Development</button></li>

                  <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleCarClick()}}  className='btn btn-gradient btn-sm fw-bold mx-3' style={{backgroundColor:"white",color:"#7E014D", fontSize:12}}>Career</button></li>
                  <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleConClick()}}  className='btn btn-gradient btn-sm fw-bold mx-3' style={{backgroundColor:"white",color:"#7E014D", fontSize:12}}>Contact us</button></li>
                  <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleEventsPage()}}  className='btn btn-gradient btn-sm fw-bold mx-3' style={{backgroundColor:"white",color:"#7E014D", fontSize:12}}>Events</button></li>
  
  
       
      </ul>
            </Nav>

            
          </Navbar.Collapse>
        </Container>
      </Navbar>
      )}

      <Offcanvas show={showOffcanvas} onHide={handleToggleOffcanvas} placement="start" style={{ maxWidth: '250px' }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{color:"#7E014D"}}>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Your menu items here */}
          <ul className="navbar-nav justify-content-center">
      

      <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleHomeClick();}}  className='btn btn-gradient btn-sm  mx-3' style={{backgroundColor:"white",color:"#7E014D"}}>Home</button></li>
      <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleAboutClick();}}  className='btn btn-gradient btn-sm mx-3' style={{backgroundColor:"white",color:"#7E014D"}}>About us</button></li>
            <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleAmazon();}}  className='btn btn-gradient btn-sm mx-3' style={{backgroundColor:"white",color:"#7E014D"}}>Amazon</button></li>
      <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleFlipkart();}}  className='btn btn-gradient btn-sm mx-3' style={{backgroundColor:"white",color:"#7E014D"}}>Flipkart</button></li>

      <li className="navbar__category nav-item">
    <div className='dropdown' onMouseEnter={handleServicesHover} onMouseLeave={handleServicesLeave}>
      <button type='button' className='btn btn-gradient btn-sm dropdown-toggle mx-3' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAllService}>
        Services
      </button>
      <ul className={`dropdown-menu ${servicesHover ? 'show' : ''}`} style={{  left: "0" }}>
        <li className="navbar__category">
          <div className='dropdown' onMouseEnter={handleEcommerceHover} onMouseLeave={handleEcommerceLeave}>
            <button type='button' className='btn btn-gradient btn-sm  dropdown-toggle' onClick={handleEcomClick} style={{backgroundColor:"white",color:"#7E014D"}}>
              E-commerce Marketing
            </button>
            <ul className={`dropdown-menu ${ecommerceHover ? 'show' : ''}`} style={{  left: "100%", top: "0" }}>
            <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm' style={{backgroundColor:"white",color:"#7E014D"}}  onClick={handleAmazon}>
              Amazon <i className="bi bi-caret-down"></i>
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonAccMgmt}>
              Account Management
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonAd}>
              Advertising
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonRein}>
              Account Reinstatement
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonReg}>
             Account Launch and Registration
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonPlus}>
              A Plus(Enhanced Brand Content)
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonStore}>
              Storefront Creation
            </button>
      
          </div>
        </li>
        
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonBrand}>
              Brand and Product-specific videos
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonList}>
              Product Listing
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonImageEdit}>
              Image Editing
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkart}>
              Flipkart <i className="bi bi-caret-down"></i>
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkartAccMgmt}>
              Flipkart Account Management
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkartAd}>
              Flipkart Advertising
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkartRpd}>
              Flipkart RPD
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkartReg}>
              Flipkart Account Launch and Registration
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkartImageEdit}>
              Flipkart Image Editing
            </button>
      
          </div>
        </li>
             
            </ul>
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleMultiPort}>
            Cataloging on Multiple Portals
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div className='dropdown' onMouseEnter={handleDigitalHover} onMouseLeave={handleDigitalLeave}>
            <button type='button' className='btn btn-gradient btn-sm  dropdown-toggle' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleDigitalMarket}>
              Digital Marketing
            </button>
            <ul className={`dropdown-menu ${digitalHover ? 'show' : ''}`} style={{  left: "100%", top: "0" }}>
  
            <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAmazonCampaign}>
              Amazon Campaign Management
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleFlipkartCampaign}>
              Flipkart Campaign Management
            </button>
      
          </div>
        </li>
  
            <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleSearchEngine}>
              Search Engine Optimisation
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleSocialMedia}>
              Social Media Marketing
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleContentMarket}>
              Content Marketing
            </button>
      
          </div>
        </li>
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleEmailMarket}>
              Email Marketing
            </button>
      
          </div>
        </li>
        
              
            </ul>
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleImageEdit}>
            Image Editing
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handlePerWeb}>
            Personal Website
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleMyntra}>
            Myntra
            </button>
      
          </div>
        </li>
  
        
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleJio}>
            Jio Mart
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleNykaa}>
            Nykaa
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleMeesho}>
            Meesho
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm ' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleShopsy}>
            Shopsy
            </button>
      
          </div>
        </li>
  
        <li className="navbar__category">
          <div>
            <button type='button' className='btn btn-gradient btn-sm' style={{backgroundColor:"white",color:"#7E014D"}} onClick={handleAjio}>
            Ajio
            </button>
      
          </div>
        </li>
  
        
  
        
        
      </ul>
    </div>
  </li>
  
  
  
                  <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleBusClick()}}  className='btn btn-gradient btn-sm  mx-3' style={{backgroundColor:"white",color:"#7E014D"}}>Business Enquiry</button></li>
                  <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleWebDevClick()}}  className='btn btn-gradient btn-sm  mx-3' style={{backgroundColor:"white",color:"#7E014D"}}>Web Development</button></li>

                  <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleCarClick()}}  className='btn btn-gradient btn-sm  mx-3' style={{backgroundColor:"white",color:"#7E014D"}}>Career</button></li>
                  <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleConClick()}}  className='btn btn-gradient btn-sm  mx-3' style={{backgroundColor:"white",color:"#7E014D"}}>Contact us</button></li>
                  <li className="navbar__category nav-item"><button type='button' onClick={()=>{handleHomeButtonClick(); handleEventsPage()}}  className='btn btn-gradient btn-sm  mx-3' style={{backgroundColor:"white",color:"#7E014D"}}>Events</button></li>
  
  
       
      </ul>
        </Offcanvas.Body>
      </Offcanvas>
        
      

    </>
  );
}



export default MyNavbar;
