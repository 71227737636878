import React from 'react'
import { useNavigate } from 'react-router-dom'
import myn from '../images/myn.jpg'

export default function Myntra() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>MYNTRA</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col animate__animated animate__pulse animate__infinite	infinite text-center '>
           
             <img src={myn} style={{maxWidth:"300px"}}/>
  
    
        </div>
        
        
        
       </div>
       
       <div className='container'>
        <div className='row'>
        
            <div className='col animate__animated animate__slideInRight '>
            <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Myntra Account Management</h4></div> 

          <br/><br/>
          <p className='font-size'>Myntra, a prominent player in the online fashion and lifestyle retail sector, offers a vast platform for sellers to showcase their products to millions of customers. At Mazeflip Services, we specialize in Myntra account management, providing comprehensive solutions to help sellers maximize their potential on the platform.</p>
          <p className='font-size'>Our expert team understands the intricacies of Myntra's seller ecosystem and offers tailored account management services to ensure seamless operations and optimal performance. From onboarding assistance to ongoing account maintenance, we guide sellers at every step of their journey on Myntra.</p>
         
          <p className='font-size'>Partner with Mazeflip Services for unparalleled support and guidance in managing your Myntra account. Together, we'll unlock new opportunities for growth and success in the dynamic world of online fashion retail.</p>
          <br/>   
            </div>


            <div className='col animate__animated animate__slideInRight '>
            <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Myntra Onboarding and Launch</h4></div> 
 
          <br/><br/>
          <p className='font-size'>Launching your brand on Myntra marks the beginning of an exciting journey in the world of online fashion retail. Mazeflip Services specializes in providing comprehensive onboarding and launch assistance to brands aspiring to establish a presence on Myntra's prestigious platform.</p>
          <p className='font-size'>Our experienced team works closely with brands to navigate the complexities of the onboarding process, ensuring a seamless transition from setup to launch. From account setup and brand registration to product listing and inventory management, we handle all aspects of the onboarding process with meticulous attention to detail.</p>
          <p className='font-size'>Partner with Mazeflip Services to embark on a successful journey of onboarding and launching your brand on Myntra. With our expertise and support, you can position your brand for success in the competitive world of online fashion retail.</p>
          <br/>   
            </div>


            <div className='col animate__animated animate__slideInRight '>
         <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Myntra Catalog Management (Listing)</h4></div> 
          <br/><br/>
          <p className='font-size'>A visually appealing and well-curated product catalog is essential for capturing the attention of customers on Myntra and driving conversions. Mazeflip Services offers comprehensive cataloging services designed to enhance the visibility and appeal of your products on Myntra's platform.</p>
          <p className='font-size'>Our dedicated team of cataloging experts specializes in creating engaging product listings that highlight the unique features and benefits of each item. From high-quality images and compelling product descriptions to accurate categorization and keyword optimization, we ensure that your products stand out in Myntra's vast catalog.</p>
          <p className='font-size'>Partner with Mazeflip Services to elevate your product catalog on Myntra and attract more customers to your brand. With our cataloging expertise, you can showcase your products in the best possible way and drive sales on one of India's leading fashion platforms.</p>
          <br/>   
            </div>
            

        </div>
       </div>
         <div className='color-change text-center'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>
      </div>
        
      </>
    )
}
