import React from 'react'
import './AmazonPage.css'
import { Link } from 'react-router-dom';
import mgmt from '../images/a1.jpg'
import adv from '../images/a2.jpg'
import rein from'../images/a3.jpg'
import launch from '../images/a4.jpg'
import aplus from '../images/a5.jpg'
import store from '../images/a6.jpg'
import vid from '../images/a8.jpg' 
import listt from '../images/a7.jpg'
import amaz from '../images/amaz.png'
import aie from '../images/aie.jpg'



export default function AmazonPage() {
    
   
   const gridItems = [
    { id: 1, title: 'Amazon Account Management', image:mgmt, link: '/amazon/account-management' },
    { id: 2, title: 'Amazon Advertising', image:adv, link: '/amazon/advertising' },
    { id: 3, title: 'Amazon Account Reinstatement', image:rein, link: '/amazon/reinstatement' },
    { id: 4, title: 'Amazon Account Launch and Registration', image: launch, link: '/amazon/register' },
    { id: 5, title: 'Amazon A plus (Enhanced Brand Content)', image: aplus, link: '/amazon/aplus' },
    { id: 6, title: 'Amazon Storefront Creation', image: store, link: '/amazon/store' },
    { id: 7, title: 'Amazon Brand and Product-specific Videos', image: vid, link: '/amazon/brand' },
    { id: 8, title: 'Amazon Product Listing', image: listt, link: '/amazon/listing' },
    { id: 9, title: 'Amazon Image Editing', image: aie, link: '/amazon/image-edit' }



    // Add more items as needed
  ];

  return (
    <>
    <div className='container-fluid'>
    
    <div className=' animate__animated animate__pulse animate__infinite	infinite text-center '>
         
         <img src={amaz} style={{maxWidth:"380px"}} alt='Amazon' className='img-fluid'/>


    
    </div>
    </div>
    <div className='container'>
    <div className="row">
        {gridItems.map((item) => (
          <div key={item.id} className="col-6 col-md-4 grid-item">
            <Link to={item.link} className="text-decoration-none">
              <img src={item.image} alt={item.title} className="img-fluid" />
              <div className="title font-size fw-bold animate__animated animate__heartBeat">{item.title}</div>
            </Link>
          </div>
        ))}
      </div>
      </div>
    
    </>
  );
}
