import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import eco from '../images/ecom.jpg'
import cat from '../images/multi.jpg'
import dig from '../images/dig.jpg'
import im from '../images/img.jpg'
import per from '../images/per.jpg'
import myn from '../images/myn.jpg'
import jio from '../images/jiom.png'
import nyk from '../images/nyka.jpg'
import mees from '../images/meesho.jpg'
import sh from '../images/shop.jpg'
import ajio from '../images/ajio.jpg'


export default function AllServices() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
 
    const gridItems = [
        { id: 1, title: 'E-Commerce Marketing', image:eco, link: '/ecommerce' },
        { id: 2, title: 'Cataloging On Multiple Portals', image:cat, link: '/multi-portals' },
        { id: 3, title: 'Digital Marketing', image:dig, link: '/digital-market' },
        { id: 4, title: 'Image Editing', image:im, link: '/image-editing' },
        { id: 5, title: 'Personal Website', image:per, link: '/perweb' },
        { id: 6, title: 'Myntra', image:myn, link: '/myntra' },
        { id: 7, title: 'Jio Mart', image:jio, link: '/jiomart' },
        { id: 8, title: 'Nykaa', image:nyk, link: '/nykaa' },
        { id: 9, title: 'Meesho', image:mees, link: '/meesho' },
        { id: 10, title: 'Shopsy', image:sh, link: '/shopsy' },
        { id: 11, title: 'Ajio', image:ajio, link: '/ajio' },
        
        
        
      ];
    
      return (
        <>
        <div className='jumbotron' style={{ paddingTop: '120px' }}>
         <h2 className='text-center fw-bold'>ALL SERVICES</h2>
            </div>

           
      <br/><br/> <br/><br/>
        
        <div className="container">
          <div className='row'>
            
          {gridItems.map((item) => (
            <div key={item.id} className="col-6 col-md-4 col-lg-3 grid-item">
              <Link to={item.link} className='text-decoration-none'>
                <img src={item.image} alt={item.title} />
                <div className="title font-size fw-bold animate__animated animate__heartBeat" >{item.title}</div>
              </Link>
            </div>
          ))}
          </div>
        </div>
        </>
      );
}
