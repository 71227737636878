import React from 'react'
import { useNavigate } from 'react-router-dom'
import nyka from '../images/nyka.jpg'

export default function Nykaa() {
  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>NYKAA</h2>
    </div>
    <div className='container'>
     <div className='row'>
    
      <div className='col animate__animated animate__pulse animate__infinite	infinite text-center '>
         
           <img src={nyka} style={{maxWidth:"300px"}}/>

  
      </div>
      
      
      
     </div>
     
     <div className='container'>
      <div className='row'>
      
          <div className='col animate__animated animate__slideInRight '>
          <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Nykaa Account Management</h4></div> 

        <br/><br/>
        <p className='font-size'>Managing your Nykaa account is crucial for optimizing your presence on one of India's largest beauty and wellness platforms. At Mazeflip Services, we offer comprehensive account management solutions tailored to meet the specific needs of your Nykaa store. Our dedicated team of account management experts works closely with you to understand your brand, products, and target audience, ensuring that your Nykaa account is set up for success.</p>
        <p className='font-size'>From initial setup and configuration to ongoing monitoring and optimization, we handle all aspects of Nykaa account management with precision and expertise. We help you navigate Nykaa's platform features, policies, and guidelines, ensuring compliance and maximizing visibility for your products. With Mazeflip Services by your side, you can trust that your Nykaa account is in capable hands, allowing you to focus on creating compelling beauty and wellness offerings for your customers.</p>
       
        
        <br/>   
          </div>


          <div className='col animate__animated animate__slideInRight '>
          <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Nykaa Onboarding and Launch</h4></div> 

        <br/><br/>
        <p className='font-size'>Embarking on your journey with Nykaa requires meticulous attention to detail and a thorough understanding of the platform's requirements. Mazeflip Services specializes in Nykaa account onboarding, guiding you through the process with ease and expertise. Our dedicated team of professionals ensures a seamless transition as you establish your presence on Nykaa, from account setup to verification and activation.</p>
        <p className='font-size'>We streamline the onboarding process, handling all necessary documentation, verification procedures, and platform configurations on your behalf. Our goal is to expedite your entry into the Nykaa ecosystem while ensuring compliance with all platform guidelines and policies. With Mazeflip Services managing your Nykaa account onboarding, you can focus on preparing your products and brand for success in the competitive beauty and wellness market.</p>
       
        <br/>   
          </div>


          <div className='col animate__animated animate__slideInRight '>
       <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Nykaa Catalog Management (Listing)</h4></div> 
        <br/><br/>
        <p className='font-size'>Effective cataloging of your products on Nykaa is essential for showcasing your beauty and wellness offerings to potential customers. At Mazeflip Services, we specialize in cataloging solutions designed to enhance the visibility and appeal of your products on the Nykaa platform. Our experienced team of cataloging experts meticulously categorizes, tags, and organizes your products, ensuring that they are easily discoverable and accessible to Nykaa's vast audience.</p>
        <p className='font-size'>We employ industry-leading techniques and best practices to optimize your product listings for maximum visibility and engagement. From crafting compelling product titles and descriptions to selecting high-quality images and setting competitive prices, we take a strategic approach to cataloging your products on Nykaa. Our goal is to help you attract more customers, drive sales, and establish a strong presence in the competitive beauty and wellness market.</p>
        <p className='font-size'>With Mazeflip Services managing your Nykaa catalog, you can rest assured that your products are presented in the best possible light, increasing their appeal and desirability among Nykaa's discerning audience. Let us help you unlock the full potential of your beauty and wellness offerings on Nykaa and achieve your business objectives with confidence.</p>
        <br/>   
          </div>
          

      </div>
     </div>
       <div className='color-change text-center'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>
    </div>
      
    </>
  )
}
