import React from 'react'
import { useNavigate } from 'react-router-dom'
import photo from '../images/photo.jpg'

export default function ProductPhoto() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron'>
       <h2 className='text-center fw-bold'>PRODUCT PHOTOGRAPHY</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col-4 animate__animated animate__slideInLeft '>
           
             <img src={photo} style={{maxWidth:"100%"}}/>
  
    
        </div>
        <div className='col-1 '></div>
        <div className='col-7 animate__animated animate__slideInRight '>
          <h5 className=''>Elevate Your Brand with Expert Product Photography</h5>
          <br/><br/>
          <p className='font-size'>Quality imagery is the cornerstone of successful online sales. In a digital landscape inundated with options, captivating visuals are your ticket to standing out amidst the competition. At Mazeflip Services, we recognize the pivotal role that professional product photography plays in elevating your brand's presence and driving conversions. Our expert team specializes in crafting visually stunning imagery tailored to the unique needs of e-commerce, A+ content, and standalone brand stores. We understand that the right visuals not only showcase your products but also convey their quality and value to potential customers. With Mazeflip Services, you can expect nothing less than exceptional product photography and graphic design solutions that set you apart and drive results.</p>
          <br/> <div className='color-change'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>  
        </div>
        
       </div>
      </div>
        
      </>
    )
}
