import { useState, useEffect } from "react";

export const Practice = () => {
    const [name, setName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    const updateData = async (name) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ success: true });
            }, 2000);
        });
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const result = await updateData(name);
        setIsLoading(false);
        setData(result);
    };

    useEffect(() => {
        if (data) {
            const timer = setTimeout(() => {
                setData(null);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [data]);

    return (
        <div className='text-center'>
            <div style={{ width: '50%' }} className='mt-4 m-lg-4'>
                <input
                    type="text"
                    className='form-control'
                    onChange={e => setName(e.target.value)}
                    value={name}
                />
                <button
                    className='btn btn-danger mt-5'
                    onClick={handleSubmit}
                >
                    {isLoading ? 'Updating' : 'Update'}
                </button>
                <span className='text-success'>
                    {data && `Changed Name To ${name}`}
                </span>
            </div>
        </div>
    );
};
