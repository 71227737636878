import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../AuthContext';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const BASE_URL = 'https://mazeflipservices.com:44344'; 
//const BASE_URL = 'http://localhost:9090'

const AdminList = () => {
  const [admins, setAdmins] = useState([]);
  const [adminToDelete, setAdminToDelete] = useState(null);
  const {accessToken, setToken} = useContext(AuthContext);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate()



  useEffect(() => {

    // Check if accessToken exists
    if (!accessToken) {
      // If accessToken doesn't exist, redirect to another page
      navigate("/admin-login");
  }

   else{
     // Fetch admins from the backend when the component mounts
     const fetchAdmins = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/admin/getalla`, {
            headers: {
              Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
            }
          });
          if (response.status===200) {
            setAdmins(response.data);
          }
          
        } catch (error) {
          console.error('Error fetching admins:', error);
        }
      };
      fetchAdmins();
   }
  }, []);

  const handleDeleteConfirmation = (adminId) => {
    setAdminToDelete(adminId);
    setShowConfirmationModal(true);
  };

  const handleDeleteAdmin = async () => {
    try {
      const response = await axios.delete(`${BASE_URL}/admin/deleteuser/${adminToDelete}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (response.status === 204) {
        setAdmins(admins.filter((admin) => admin.user_id !== adminToDelete));
      }
    } catch (error) {
      console.error('Error deleting admin:', error);
    } finally {
      setAdminToDelete(null);
      setShowConfirmationModal(false);
    }
  };
  

  return (
    <div>
        <div className='jumbotron'>
        <h2 className='text-center'>Admin List</h2>
        </div>
      
     <div className='container table-responsive'>
     <table className="table table-bordered table-striped ">
        <thead className='thead thead-dark'>
          <tr>
            <th>Admin ID</th>
            <th>Username</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {admins.map((admin) => (
            <tr key={admin.user_id}>
              <td>{admin.user_id}</td>
              <td>{admin.username}</td>
              <td>{admin.email}</td>
              <td>{admin.mobile}</td>
              <td>
                <button className="btn btn-danger" onClick={() => handleDeleteConfirmation(admin.user_id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
     </div>

      {/* Delete confirmation modal */}
      <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the admin?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDeleteAdmin}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminList;
