import React from 'react'
import fie from '../../images/fie.jpg'
import { useNavigate } from 'react-router-dom'

export default function FlipkartImageEdit() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>FLIPKART IMAGE EDITING</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col-4 animate__animated animate__slideInLeft '>
           
             <img src={fie} style={{maxWidth:"100%"}} alt='Fimage' className='img-fluid'/>
  
    
        </div>
        <div className='col-1 '></div>
        <div className='col-7 animate__animated animate__slideInRight '>
          <h5 className=''>Expert Flipkart Image Editing Solutions</h5>
          <br/><br/>
          <p className='font-size'>Transform your Flipkart product listings with expert image editing solutions provided by Mazeflip Services. Our experienced team adeptly enhances product images, adhering to Flipkart's guidelines to ensure they grab attention and resonate with shoppers. From background removal to enhancing product details and adjusting colors, we refine your images to perfection, enhancing their appeal and driving engagement. Partner with Mazeflip Services for professional Flipkart image editing services that elevate your brand and drive conversions.</p>
          <br/> <div className='color-change'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>  
        </div>
        
       </div>
      </div>
        
      </>
    )
}
