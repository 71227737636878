import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useContext, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from './AuthContext'

const BASE_URL = 'https://mazeflipservices.com:44344'; 

export default function UpdateCarEnq() {
  const {accessToken, setToken} = useContext(AuthContext);

  const navigate = useNavigate()
  const { cId,posId } = useParams();
  const [selectedCarOption, setSelectedCarOption] = useState('');
  const [positionOptions, setPositionOptions] = useState([]);
  const [submissionCarStatus, setSubmissionCarStatus] = useState(null);
  const [enquiryCarData, setEnquiryCarData] = useState({
    fullName: '',
    email: '',
    mobileNumber: '',
    positionApplied: { posId: '' } // Initialize with default value
});

/////////////////////////////////////////////////////////////////////////////

useEffect(()=>{

  // Check if accessToken exists
if (!accessToken) {
// If accessToken doesn't exist, redirect to another page
navigate("/admin-login")

}
},[accessToken, navigate])

/////////////////////////////////////////////////////////////////////////////

useEffect(() => {
 if(accessToken){
   // Fetch career page data based on posId
   axios.get(`${BASE_URL}/posts/getonepos/${posId}`, {
    headers: {
        Authorization: `Bearer ${accessToken}`
    }
})
    .then(response => {
        // Find the career page with the provided cId
        const careerPage = response.data.careerPages.find(cp => cp.cId === parseInt(cId));
        if (careerPage) {
            setEnquiryCarData({
                cId: careerPage.cId,
                fullName: careerPage.fullName,
                email: careerPage.email,
                mobileNumber: careerPage.mobileNumber,
                positionApplied: { posId: posId }
            });
        }
    })
    .catch(error => {
        console.error('Error fetching career page data:', error);
    });
 }
}, [cId]);

/////////////////////////////////////////////////////////////////////////


useEffect(() => {
  // Fetch position names
  axios.get(`${BASE_URL}/posts/all`)
    .then(response => {
      setPositionOptions(response.data);
    })
    .catch(error => {
      console.error('Error fetching service options:', error);
    });
}, []);

////////////////////////////////////////////////////////////////////////////////

const handleCarSubmit = async (event) => {
  event.preventDefault();
 
  try {
    const response = await axios.patch(`${BASE_URL}/admin/updatecp/${posId}/${cId}`, enquiryCarData,{
      headers: {
        Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
      }
    });
    if (response.status === 200) {
        // Update submission status
        setSubmissionCarStatus('success');

       

        // Reset submission status after 3 seconds
        setTimeout(() => {
            setSubmissionCarStatus(null);
            navigate('/admin-login/admin-career')
        }, 3000);

        // Optionally, you can navigate to another page or handle success response here
    }
} catch (error) {
    console.error('Error updating business enquiry:', error);
    // Optionally, handle error response here
}
};

///////////////////////////////////////////////////////////////////////////////

const handleCarChange = (event) => {
  const { name, value } = event.target;
  if (name === "positionApplied") {
      setEnquiryCarData({
          ...enquiryCarData,
          positionApplied: {
              posId: value
          }
      });
  } else {
      setEnquiryCarData({
          ...enquiryCarData,
          [name]: value
      });
  }
};




  return (
    <>
    <div className='container border'>
      <div className='container-fluid' ><h3 className='text-center'>Update Career Page</h3></div>
      
      <div className='container-fluid' style={{maxWidth:"50%"}}>
      <form method='patch' onSubmit={handleCarSubmit}  className='form-group'>
        <div className='row'>
           <div className='col'>
           <label htmlFor='cId'>ID</label>
            <input type='number' id='cId' name='cId' value={enquiryCarData.cId || ''}  className='form-control' disabled />
           </div>
        </div>
          <div className='row'>
            <div className='col-6'>
            <label htmlFor='fullName'>Full Name*</label>
            <input type='text' id='fullName' name='fullName' value={enquiryCarData.fullName || ''}  onChange={handleCarChange} className='form-control' required />
            </div>
            <div className='col-6'>
            <label htmlFor='email'>Email*</label>
            <input type='email' id='email' name='email' value={enquiryCarData.email || ''}  onChange={handleCarChange} className='form-control' required />
            </div>
            
          </div>
          <br/>
          
          <div className='row'>
           <div className='col-6'>
           <label htmlFor='positionApplied'>Select Position*</label><br/>
              <select onChange={handleCarChange}   id='positionApplied' name='positionApplied' value={enquiryCarData.positionApplied.posId || ''} className='form-control' required >
              <option value="">Please select</option>
              {positionOptions.map((option, index) => (
                    <option key={index} value={option.posId}>{option.posName}</option>
                  ))}
              </select>
           </div>
           <div className='col-6'>
           <label htmlFor='mobileNumber'>Mobile Number*</label>
            <input type='text' id='mobileNumber'  onChange={handleCarChange} name='mobileNumber' value={enquiryCarData.mobileNumber || ''} className='form-control' required/>
           </div>
          </div>
         
          
         
            <br/>
            <div className='row justify-content-center'>
            <div className='col-6 offset-3'>
            
            <button type='submit' className='btn btn-primary text-white'>Submit</button>
            </div>
            </div>
             {/* Conditional rendering for success message */}
       {submissionCarStatus === 'success' && (
            <Alert variant='success' dismissible>
              Form updated successfully!
            </Alert>
          )}
            
          
        </form>
      </div>
    </div>
      
    </>
  )
}
