import React from 'react'
import { useNavigate } from 'react-router-dom'
import fcam from '../../images/fcam.jpg'

export default function FlipkartCamp() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>FLIPKART CAMPAIGN MANAGEMENT</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col-4 animate__animated animate__slideInLeft '>
           
             <img src={fcam} style={{maxWidth:"100%"}} className='img-fluid' alt='Fcamp'/>
  
    
        </div>
        <div className='col-1 '></div>
        <div className='col-7 animate__animated animate__slideInRight '>
          <h5 className=''>Maximize Your Flipkart Campaigns with Mazeflip Services</h5>
          <br/><br/>
          <p className='font-size'>Mazeflip Services excels in comprehensive Flipkart Campaign Management, offering customized strategies to enhance product visibility and drive sales on the Flipkart platform. With our expertise, we meticulously analyze market trends, conduct thorough keyword research, and craft compelling ad creatives to ensure maximum impact. Our dedicated team at Mazeflip Services diligently monitors campaign performance, adjusting bids, refining targeting parameters, and optimizing ad content to deliver optimal results. By leveraging data-driven insights and employing proven strategies, Mazeflip Services empowers sellers and brands to achieve their advertising goals on Flipkart. Partner with Mazeflip Services for unparalleled Flipkart Campaign Management solutions tailored to your specific needs and objectives.</p>
          <br/> <div className='color-change'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>  
        </div>
        
       </div>
      </div>
        
      </>
    )
}
