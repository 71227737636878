import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import Cookies from 'js-cookie';

const BASE_URL = 'https://mazeflipservices.com:44344'; 
//const BASE_URL = 'http://localhost:9090'; 

const UserLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate()
  
 

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}/admin/login`, {
        email,
        password,
      });

      if(response.status === 200){

        // Handle successful login, e.g., redirect to dashboard
      console.log('Login successful:', response.data.accessToken);
      const accesstoken =response.data.accessToken;
      
       //localStorage.setItem('accessToken',accesstoken)
       Cookies.set('accessToken', accesstoken ,{expires: 7});

        
        setShowAlert(true)
        setAlertMessage('Login successful');
        // Clear input fields after successful login
      setEmail('');
      setPassword('');
        setTimeout(() => {
          if(accesstoken){
            navigate('/admin-login/admin-home')
          }
          
        }, 3000);
      
      }
      else {
        // Handle unsuccessful login
        setShowAlert(true);
        setAlertMessage('Login failed');
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } catch (error) {
      // Handle login error
      console.error('Login failed:', error);
      setShowAlert(true);
      setAlertMessage('Login failed due to an error');
      
    }
    finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }


  };

  return (
    <>
    <div className='jumbotron'>
      <h2 className='text-center fw-bold'>Admin Login</h2>
    </div>
    <div className='container-fluid border border-dark p-5' style={{maxWidth:"50%", backgroundColor:'pink'}}>
    <form onSubmit={handleSubmit} className='form-group'>
      <div>
      <label htmlFor='email' className='fw-bold'>Authorized Email</label>
      <input
        type="email"
        placeholder="Email" id='email' className='form-control'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      </div><br/><br/>
      
      <div>
        <label htmlFor='password' className='fw-bold'>Password</label>
      <input
        type="password"
        placeholder="Password" id='password' className='form-control'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      </div><br/><br/>
      <div className='admin-button'>
      <button type="submit" className='btn btn-block btn-sm text-white'>Login</button>
   

{showAlert && (
  <Alert  variant={alertMessage === 'Login successful' ? 'success' : 'danger'} onClose={() => setShowAlert(false)} dismissible>
  {alertMessage}
  </Alert>
)}
      </div>
      
      
    </form>
    </div>
    </>
  );
};

export default UserLogin;
