import React from 'react'
import { useNavigate } from 'react-router-dom'
import ajio from '../images/ajio.jpg'

export default function Ajio() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>AJIO</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col animate__animated animate__pulse animate__infinite infinite text-center '>
           
             <img src={ajio} style={{maxWidth:"300px"}}/>
  
    
        </div>
        
        
        
       </div>
       
       <div className='container'>
        <div className='row'>
        
            <div className='col animate__animated animate__slideInRight '>
            <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Ajio Account Management</h4></div> 

          <br/><br/>
          <p className='font-size'>Successfully managing your seller account on Ajio requires a combination of strategic planning, meticulous execution, and ongoing optimization. At Mazeflip Services, we offer comprehensive account management and cataloging services designed to help sellers maximize their presence and sales on Ajio's platform.</p>
          <p className='font-size'>Our experienced team of account managers works closely with sellers to oversee all aspects of their Ajio accounts, including product listing, inventory management, order processing, customer service, and performance tracking. We take a proactive approach to identify opportunities for growth and optimization, helping sellers achieve their sales targets and drive business success on Ajio.</p>
         
          <p className='font-size'>Partner with Mazeflip Services for expert account management and cataloging services on Ajio and take your online business to new heights.</p>
          <br/>   
            </div>


            <div className='col animate__animated animate__slideInRight '>
            <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Ajio Onboarding and Launch</h4></div> 
 
          <br/><br/>
          <p className='font-size'>Launching your brand on Ajio opens doors to a vast network of fashion-forward customers and unparalleled opportunities for growth in the online fashion industry. At Mazeflip Services, we specialize in providing comprehensive onboarding and launch assistance to brands looking to establish a presence on Ajio's esteemed platform.</p>
          <p className='font-size'>Our dedicated team works closely with brands to navigate the complexities of the onboarding process, ensuring a smooth transition from setup to launch. From account setup and brand registration to product listing and inventory management, we handle all aspects of the onboarding journey with precision and expertise.</p>
          <p className='font-size'>Partner with Mazeflip Services to unlock the full potential of selling on Ajio and position your brand for success in the competitive world of online fashion retail.

</p>
          <br/>   
            </div>


            <div className='col animate__animated animate__slideInRight '>
         <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Ajio Catalog Management (Listing)</h4></div> 
          <br/><br/>
          <p className='font-size'>Effectively managing your product catalog on Ajio is essential to maximizing visibility, attracting customers, and driving sales. At Mazeflip Services, we offer expert catalog management (listing) services tailored to help sellers optimize their product listings on Ajio's platform.</p>
          <p className='font-size'>Our experienced team of catalog management specialists works diligently to ensure that your products are accurately and effectively represented on Ajio. We follow best practices for product listing optimization.</p>
          <p className='font-size'>By entrusting your catalog management (listing) needs to Mazeflip Services, you can rest assured that your products will be presented professionally and effectively on Ajio, helping you attract more customers and drive sales.</p>
          <br/>   
            </div>
            

        </div>
       </div>
         <div className='color-change text-center'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>
      </div>
        
      </>
    )
}
