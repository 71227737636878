import React from 'react'
import { useNavigate } from 'react-router-dom'
import cont from '../../images/content.jpg'

export default function Content() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>CONTENT MARKETING</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col-4 animate__animated animate__slideInLeft '>
           
             <img src={cont} style={{maxWidth:"100%"}} className='img-fluid' alt='content'/>
  
    
        </div>
        <div className='col-1 '></div>
        <div className='col-7 animate__animated animate__slideInRight '>
          <h5 className=''>Revolutionize Your Brand's Presence with Dynamic Content Marketing</h5>
          <br/><br/>
          <p className='font-size'>Transform your brand's online presence with our innovative and captivating content marketing strategies. At Mazeflip Services, we specialize in crafting dynamic and creative content that captivates audiences and drives meaningful engagement. Our team of content marketing experts combines creativity with strategic insights to develop tailored content strategies that resonate with your target audience and achieve your business objectives. From compelling blog posts and informative articles to engaging videos and interactive infographics, we leverage a diverse range of content formats to effectively convey your brand's message and establish thought leadership in your industry. With our proven track record of delivering high-quality content that drives results, we empower brands to stand out in a crowded digital landscape and connect with their audience on a deeper level. Elevate your brand's content marketing efforts with Mazeflip Services and unlock new opportunities for growth and success.</p>
          <br/> <div className='color-change'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>  
        </div>
        
       </div>
      </div>
        
      </>
    )
}
