import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faYoutube, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import maze from '../images/maze.jpg'
import { Link } from 'react-router-dom';



export default function Footer() {
   
  return (
    
    
      <>
      <footer className="text-center text-lg-start bg-body-tertiary text-muted">
      {/* Section: Social media */}
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        {/* Left */}
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>
        {/* Left */}

        {/* Right */}
        <div>
            <a href="https://api.whatsapp.com/send?phone=918789541680" className="me-4 text-reset">
              <FontAwesomeIcon icon={faWhatsapp} size='2xl' />
              </a>

          <a href="https://www.facebook.com/profile.php?id=100063877067060" className="me-4 text-reset">
          <FontAwesomeIcon icon={faFacebook} size='2xl'/>
          </a>
          <a href="https://twitter.com/SELLERSGUIDESOL" className="me-4 text-reset">
          <FontAwesomeIcon icon={faTwitter} size='2xl'/>
          </a>
          <a href="https://www.youtube.com/mazeflip" className="me-4 text-reset">
          <FontAwesomeIcon icon={faYoutube} size='2xl'/>
          </a>
          <a href="https://www.instagram.com/sellersguidesolution" className="me-4 text-reset">
          <FontAwesomeIcon icon={faInstagram} size='2xl'/>
          </a>
         
        </div>
        {/* Right */}
      </section>
      {/* Section: Social media */}

      {/* Section: Links  */}
      <section className="">
        <div className="container text-center text-md-start mt-5">
          {/* Grid row */}
          <div className="row mt-3">
            {/* Grid column */}
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              {/* Content */}
              <h6 className="text-uppercase fw-bold mb-4">
                <img src={maze} style={{maxWidth:'150px'}} className='img-fluid'/>
              </h6>
              <p>
              Empowering Businesses to Thrive in the Digital Realm! Offering premier solutions in e-commerce marketing, digital marketing, and cutting-edge website design and development services that drive tangible results and open doors to boundless possibilities.
              </p>
            </div>
            {/* Grid column */}
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              {/* Content */}
              <h6 className="text-uppercase fw-bold mb-4" style={{color:"#7E014D"}}>
                OUR SERVICES
              </h6>
              <p><a href="/amazon" className='text-decoration-none text-muted'>Amazon</a> </p>
              <p><a href="/flipkart" className='text-decoration-none text-muted'>Flipkart</a> </p>
              <p><a href="/ecommerce" className='text-decoration-none text-muted'>E-commerce Marketing</a> </p>
              <p><a href='/web-dev' className='text-decoration-none text-muted'>Web Development</a></p>
            <p><a href='/digital-market' className='text-decoration-none text-muted'>Digital Marketing</a></p>
            <p><a href='/nykaa' className='text-decoration-none text-muted'>Nykaa</a></p>
            <p><a href='/jiomart' className='text-decoration-none text-muted'>Jio Mart</a></p>

            </div>
            {/* Add more Grid columns here */}
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              {/* Content */}
              <h6 className="text-uppercase fw-bold mb-4" style={{color:"#7E014D"}}>
                QUICK LINKS
              </h6>
              <p><a href='/about' className='text-decoration-none text-muted'>About us</a></p>
            <p><a href='/contacts' className='text-decoration-none text-muted'>Contact us</a></p>

            </div>


            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              {/* Content */}
              <h6 className="text-uppercase fw-bold mb-4" style={{color:"#7E014D"}}>
                CONTACT US
              </h6>
              <p>MAZEFLIP SERVICES,
Ward No-20 ,
Sultanpur, East Matth,
PO Danapur Cantt,
Patna, Bihar,
PIN:801503</p>
            <p><i class="bi bi-telephone-fill"></i><a href='tel:+918789541680' className='text-decoration-none text-muted'>  8789541680</a> </p>
            <a href='mailto:contact@mazeflipservices.com' className="text-decoration-none text-muted" ><i class="bi bi-envelope-at-fill"></i>  contact@mazeflipservices.com</a>

            </div>
          </div>
          {/* Grid row */}
        </div>
      </section>
      {/* Section: Links  */}

      {/* Copyright */}
      <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
      <p>&copy; {new Date().getFullYear()} Mazeflip Services. All rights reserved.</p>

      </div>
     
    </footer>
    </>  
    
  )
}
