import React from 'react'
import search from '../../images/search.jpg'
import { useNavigate } from 'react-router-dom'

export default function SearchEngine() {
 
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>SEARCH ENGINE OPTIMIZATION</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col-4 animate__animated animate__slideInLeft '>
           
             <img src={search} style={{maxWidth:"100%"}} className='img-fluid' alt='Sengine'/>
  
    
        </div>
        <div className='col-1 '></div>
        <div className='col-7 animate__animated animate__slideInRight '>
          <h5 className=''>Boost Website Traffic Through Expert SEO Solutions</h5>
          <br/><br/>
          <p className='font-size'>Unlock the full potential of your online presence with our expertly crafted SEO services. At Mazeflip Services, we specialize in elevating your website's visibility and driving targeted traffic through strategic search engine optimization techniques. Our seasoned team of SEO professionals employs a comprehensive approach to optimize your website's content, structure, and performance, ensuring maximum visibility across search engine results pages. By leveraging industry-leading tools and techniques, we meticulously analyze your website's performance, identify key areas for improvement, and implement tailored strategies to enhance its search engine rankings. From keyword research and on-page optimization to technical SEO audits and link building, we provide a holistic suite of services designed to propel your website to the forefront of search engine results. With our proven track record of success and unwavering commitment to delivering results, you can trust Mazeflip Services to be your partner in driving sustainable growth and success online.</p>
          <br/> <div className='color-change'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>  
        </div>
        
       </div>
      </div>
        
      </>
    )
}
