import React from 'react'
import './AmazonReg.css'
import a4 from '../../images/a4.jpg'
import { useNavigate } from 'react-router-dom'

export default function AmazonReg() {
  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>AMAZON ACCOUNT LAUNCH AND REGISTRATION</h2>
    </div>
    <div className='container'>
     <div className='row'>
    
      <div className='col-4 animate__animated animate__slideInLeft '>
         
           <img src={a4} style={{maxWidth:"100%"}} alt='Alaunch' className='img-fluid'/>

  
      </div>
      <div className='col-1 '></div>
      <div className='col-7 animate__animated animate__slideInRight '>
        <h5 className=''>Safely Launch with Mazeflip: Expert Amazon Setup & Launch Services.</h5>
        <br/><br/>
        <p className='font-size'>Streamline Your Amazon Entry with Mazeflip Services: Seamless Account Registration & Launch Solutions. Trust in our meticulous approach to ensure compliance with Amazon's stringent requirements, facilitating a secure and monitored account launch. Rest assured, our dedicated team prioritizes confidentiality and adheres to industry best practices, fostering a robust foundation for accelerated sales growth and exceptional customer service.</p>
        <br/> <div className='color-change'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>  
      </div>
      
     </div>
    </div>
      
    </>
  )
}
