import axios from 'axios';
import Cookies from 'js-cookie'
import { AuthContext } from '../AuthContext';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import BusinessEnquiryItem from '../BusinessEnquiryItem';
import { Button } from 'react-bootstrap';

const BASE_URL = 'https://mazeflipservices.com:44344'; 
//const BASE_URL = 'http://localhost:9090'; 


export default function AdminHome() {

    //const[accessToken, setToken]=  useState(Cookies.get('accessToken'))
    const {accessToken, setToken} = useContext(AuthContext);

    const navigate = useNavigate()
    const[businessEnquiries, setBusinessEnquiries]=useState([])

  //   useEffect(()=>{

         
  //    if (!accessToken) {
   
  //   navigate("/admin-login")
  // }
  //   },[accessToken, navigate])

    useEffect(() => {
        
          const handleBusinessEnquiries = async () => {
            try {
              const response = await axios.get(`${BASE_URL}/admin/getallbe`, {
                headers: {
                  Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
                }
              });
              setBusinessEnquiries(response.data);
              console.log('Business Enquiries:', response.data);
            } catch (error) {
              console.error('Error fetching business enquiries:', error);
            }
          };
          if (accessToken) {
            handleBusinessEnquiries();
          } else {
            navigate('/admin-login');
          }
        
      }, [accessToken, navigate]);


      const handleDeleteEnquiry = async(id) => {
        // Logic to delete the enquiry with the given ID from the state or send a request to the backend
       try {
        
        const response = await axios.delete(`${BASE_URL}/admin/delete/${id}`,{
            headers: {
              Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
            }
          })

          if (response.status === 204) {
            console.log('Deleted successfully')
            window.location.reload(); // Reload the page
          } 

          else if (response.status === 404) {
            console.log('Not found')
          } 


       } catch (error) {
        console.log('Not Deleted ',error)

       }
          
      };

      /////////////////////////////////////////////////////////////////////////

      const handleLogout = async()=>{
        try {

          const response = await axios.post(`${BASE_URL}/admin/logout`,null,
          {
          headers: {
            Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
          }
        })
        if (response.status ===200) {
          
          //localStorage.removeItem('accessToken')
          Cookies.remove('accessToken');
          navigate('/admin-login')
        }
       else if (response.status ===404) {
        
        console.log('No token is there')
       } 
          
        } catch (error) {
          console.log('Some error occurred ',error)
        }

        

      }

      const handleCareerPage=()=>{
        navigate('/admin-login/admin-career')
      }

      const handleDeptEnquiry=()=>{
        navigate('/admin-login/admin-dept')
      }

      const handleServicePage = ()=>{
        navigate('/admin-login/all-services')
      }


  return (
    <>
    <div className='jumbotron p-3'>
   <h2 className='text-center'>ADMIN</h2>
    </div>
    <div className='container' style={{maxWidth:"90%"}}>
      <div className='row'>
       <div className=''>
       <h4 className='text-center'>Business Enquiry</h4>
       <br/><br/>
       </div>
       
      </div>
      <div className='row'>
    <div className='col-sm-6 col-md-3 mb-2 logout-button '> {/* Add margin bottom */}
        <button type='button' className='btn text-white' onClick={handleCareerPage}>Career Page</button>
    </div>
    <div className='col-sm-6 col-md-3 mb-2 logout-button '> {/* Add margin bottom */}
        <button type='button' className='btn text-white' onClick={handleDeptEnquiry}>Department</button>
    </div>
    <div className='col-sm-6 col-md-3 mb-2 logout-button '> {/* Add margin bottom */}
        <button type='button' className='btn text-white' onClick={handleServicePage}>Services</button>
    </div>

    
    <div className='col-sm-6 col-md-3 mb-2 logout-button'> {/* Add margin bottom */}
        <button type='button' className='btn text-white' onClick={handleLogout}>Logout</button>
    </div>
</div>

      <br/>
      
      <div className='text-center table-responsive '>
        <table className='table table-bordered table-hover table-striped '>
            <thead className='thead-dark'>
            <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Business Email</th>
            <th>Contact No:</th>
            <th>Address</th>
            <th>Message</th>
            <th>Service</th>
            <th>Update</th>
            <th>Delete</th>

            </tr>
            </thead>
            <tbody>
                
                    {businessEnquiries.map((oneEnquiry,index)=>(<tr key={index}>
                        <td>{oneEnquiry.bId}</td>
                        <td>{oneEnquiry.fName}</td>
                        <td>{oneEnquiry.lName}</td>
                        <td>{oneEnquiry.bEmail}</td>
                        <td>{oneEnquiry.contactNumber}</td>
                        <td>{oneEnquiry.address}</td>
                        <td>{oneEnquiry.message}</td>
                        <td>{oneEnquiry.selectedService.serviceName}{" "}{oneEnquiry.selectedService.subServiceName}</td>
                        <td>
                        <div style={{backgroundColor:"green"}} className='p-2' >
          <Link to={`/admin-login/admin-home/update-benquiry/${oneEnquiry.bId}`} className='text-decoration-none text-white'>
            Update
          </Link>
        </div>
                        </td>
                       
                        {/* <td><button type='button' className='btn btn-primary text-light'>Remove</button></td> */}
                        <BusinessEnquiryItem key={oneEnquiry.bId}
              enquiry={oneEnquiry}
              onDelete={handleDeleteEnquiry}/>
                        
                        </tr>))}
                
            </tbody>
            
           
        </table>
      </div>
    </div>
      
    </>
  )
}
