import React, { useEffect, useState } from 'react'
import Carousel  from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css'
import ban1 from '../images/ban1.jpg'
import ban2 from '../images/ban2.jpg'
import ban3 from '../images/ban3.jpg'
import ban4 from '../images/ban4.webp'




export default function MyCarousal() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);


  const captions = [
    "Empowering businesses through innovative solutions",
    "Building partnerships for sustainable growth",
    "Transforming ideas into impactful results",
   
    // Add more captions as needed
  ];

  useEffect(() => {
    // Preload all images to ensure smooth transitions
    const imagePaths = [
      ban2,
      ban3,
      ban4
      // ... (add more image paths)
    ];

    const promises = imagePaths.map((path) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = path;
        img.onload = () => resolve(img);
        img.onerror = reject;
      });
    });

    Promise.all(promises).then((loadedImages) => {
      setImages(loadedImages);
      setLoading(false);
    }).catch((error) => {
      console.error('Error loading images:', error);
      // Handle loading errors gracefully (e.g., display a fallback message)
    });
  }, []);

  if (loading) {
    return <div>Loading images...</div>; // Or display a loading indicator
  }

  return (
    <div className="full-screen-carousel" style={{ paddingTop: '100px' }}>
      <Carousel interval={3000} fade>
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100 img-fluid"
              src={image.src}
              alt={`Slide ${index + 1}`}
            />
            <Carousel.Caption>
            <h3 className='word-outline'>{captions[index]}</h3>
              {/* Add captions as needed */}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};