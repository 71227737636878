import React from 'react'
import './AmazonListing.css'
import { useNavigate } from 'react-router-dom'
import a7 from '../../images/a7.jpg'


export default function AmazonListing() {
  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>AMAZON PRODUCT LISTING</h2>
    </div>
    <div className='container'>
     <div className='row'>
    
      <div className='col-4 animate__animated animate__slideInLeft '>
         
           <img src={a7} style={{maxWidth:"100%"}} alt='AList' className='img-fluid'/>

  
      </div>
      <div className='col-1 '></div>
      <div className='col-7 animate__animated animate__slideInRight '>
        <h5 className=''>Assured Sales Through Meticulous Product Listing</h5>
        <br/><br/>
        <p className='font-size'>Struggling with the arduous task of uploading countless catalog pictures without seeing a tangible impact on sales or enhancing the buyer's journey? Look no further than Mazeflip Services. Our expertise in Amazon product listing is unparalleled, offering a seamless and impactful solution to elevate both your brand and customer experience. With seasoned professionals at the helm, we craft meticulously curated product listings designed to surpass your competitors and resonate with your target audience. Backed by years of experience and a proven track record of client satisfaction, Mazeflip Services is your trusted partner for outsourcing Amazon account management. Whether you're grappling with the complexities of account handling or seeking to streamline your business operations, our dedicated team is committed to delivering visible results by meticulously attending to every detail. Partner with us and experience a transformation in your Amazon presence that drives tangible growth and success.</p>
        <br/> <div className='color-change'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>  
      </div>
      
     </div>
    </div>
      
    </>
  )
}
