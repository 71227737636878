import logo from './logo.svg';
import './App.css';
import MyNavbar from './Components/MyNavbar';
import MyCarousal from './Components/MyCarousal';
import Footer from './Components/Footer';
import { Route, BrowserRouter as Router, Routes, Navigate, useNavigate} from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import UserRegister from './Components/UserRegister';
import axios from 'axios';
import Cookies from 'js-cookie';
import AuthProvider from './Components/AuthProvider';
import { AuthContext } from './Components/AuthContext';
import { jwtDecode } from 'jwt-decode';
import Aboutus from './Components/Aboutus';
import BusinessEnq from './Components/BusinessEnq';
import CareerPage from './Components/CareerPage';
import Contacts from './Components/Contacts';
import AmazonPage from './Components/AmazonPage';
import AmazonAccMgmt from './Components/AmazonFolder/AmazonAccMgmt';
import FlipkartPage from './Components/FlipkartPage';
import AmazonAdv from './Components/AmazonFolder/AmazonAdv';
import AmazonRein from './Components/AmazonFolder/AmazonRein';
import AmazonReg from './Components/AmazonFolder/AmazonReg';
import AmazonPlus from './Components/AmazonFolder/AmazonPlus';
import AmazonStore from './Components/AmazonFolder/AmazonStore';
import AmazonVideos from './Components/AmazonFolder/AmazonVideos';
import AmazonListing from './Components/AmazonFolder/AmazonListing';
import FlipkartAccMgmt from './Components/FlipkartFolder/FlipkartAccMgmt';
import FlipkartAd from './Components/FlipkartFolder/FlipkartAd';
import FlipkartRpd from './Components/FlipkartFolder/FlipkartRpd';
import FlipkartReg from './Components/FlipkartFolder/FlipkartReg';
import Ecom from './Components/Ecom';
import Nykaa from './Components/Nykaa';
import JioMart from './Components/JioMart';
import ProductPhoto from './Components/ProductPhoto';
import SearchEngine from './Components/Digitalmarketing/SearchEngine';
import SocialMedia from './Components/Digitalmarketing/SocialMedia';
import Email from './Components/Digitalmarketing/Email';
import Content from './Components/Digitalmarketing/Content';
import DigitalMarket from './Components/DigitalMarket';
import Ajio from './Components/Ajio';
import Meesho from './Components/Meesho';
import Shopsy from './Components/Shopsy';
import PersonalWebsite from './Components/PersonalWebsite';
import Myntra from './Components/Myntra';
import AmazonCamp from './Components/Digitalmarketing/AmazonCamp';
import FlipkartCamp from './Components/Digitalmarketing/FlipkartCamp';
import AmazonImageEdit from './Components/AmazonFolder/AmazonImageEdit';
import FlipkartImageEdit from './Components/FlipkartFolder/FlipkartImageEdit';
import CampaignMultiple from './Components/CampaignMultiple';
import ImageEditing from './Components/ImageEditing';
import UserLogin from './Components/UserLogin';
import AdminHome from './Components/AdminFiles/AdminHome';
import UpdateBusEnq from './Components/UpdateBusEnq';
import AdminCareer from './Components/AdminFiles/AdminCareer';
import UpdateCarEnq from './Components/UpdateCarEnq';
import AdminDepartments from './Components/AdminFiles/AdminDepartments';
import EventPage from './Components/EventPage';
import AdminList from './Components/AdminFiles/AdminList';
import AllServices from './Components/AllServices';
import ServicePage from './Components/AdminFiles/ServicePage';
import {Practice} from "./Components/Practice";
import WebDev from './Components/WebDev';


function App() {

  //const [cartCount, setCartCount] = useState(0);
 // const [loggedIn, setLoggedIn] = useState(false);
  //const [showLoginForm, setShowLoginForm] = useState(false);
 // const [receivedEmail, setReceivedEmail] = useState('');
 // const [isLoading, setIsLoading] = useState(true); // Add a loading state
 // const [accessToken, setToken] = useState(localStorage.getItem('accessToken'));
const {accessToken, setToken} = useContext(AuthContext);


   // const[accessToken, setToken]=  useState(Cookies.get('accessToken'))
 

 

  
  // useEffect(() => {
  //   // This useEffect will run whenever receivedEmail changes
  //   console.log('Received email updated:', receivedEmail);
  // }, [receivedEmail]);

 


  // const handleReceiveEmail = (data) => {
  //   setReceivedEmail(data);
  // };


  

  


  //   useEffect(() => {
  //   // Check if accessToken exists
  //   if (accessToken) {
  //     // Decode the token to get its expiration time
  //     const decodedToken = jwtDecode(accessToken);

  //     // Check if the token is expired
  //     if (decodedToken.exp * 1000 < Date.now()) {
  //       // Token is expired, remove it from localStorage and update state
  //       localStorage.removeItem('accessToken');
  //       setToken(null);
  //       setLoggedIn(false);
  //       setReceivedEmail('');
  //     }
  //   }

  //   // ... rest of your code
  // }, [accessToken]);
 
  

  return (
    <>
    
    
    <Router>
      
    
    <Routes>
      <Route exact path='/' element={
        <React.Fragment>
             <MyNavbar/>
    <MyCarousal/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

   <Route exact path='/about' element={
        <React.Fragment>
           <MyNavbar/>
    <Aboutus/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

  <Route exact path='/buseq' element={
        <React.Fragment>
           <MyNavbar/>
    <BusinessEnq/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>
    <Route exact path='/careerpg' element={
        <React.Fragment>
           <MyNavbar/>
    <CareerPage/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

<Route exact path='/contacts' element={
        <React.Fragment>
           <MyNavbar/>
    <Contacts/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

<Route exact path='/amazon' element={
        <React.Fragment>
           <MyNavbar/>
    <AmazonPage/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>
      

      <Route exact path='/amazon/account-management' element={
        <React.Fragment>
           <MyNavbar/>
             <AmazonAccMgmt/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}

<Route exact path='/amazon/advertising' element={
        <React.Fragment>
           <MyNavbar/>
             <AmazonAdv/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>



      {/*////////////////////////////////////////////////////////////////////////////// */}

      <Route exact path='/amazon/reinstatement' element={
        <React.Fragment>
           <MyNavbar/>
             <AmazonRein/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}


      <Route exact path='/amazon/register' element={
        <React.Fragment>
           <MyNavbar/>
             <AmazonReg/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}


      <Route exact path='/amazon/aplus' element={
        <React.Fragment>
           <MyNavbar/>
             <AmazonPlus/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}

      <Route exact path='/amazon/store' element={
        <React.Fragment>
           <MyNavbar/>
             <AmazonStore/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}
<Route exact path='/practice' element={
    <React.Fragment>
        <Practice/>
    </React.Fragment>
}/>
     
      {/*////////////////////////////////////////////////////////////////////////////// */}

      <Route exact path='/amazon/brand' element={
        <React.Fragment>
           <MyNavbar/>
             <AmazonVideos/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}

      <Route exact path='/amazon/listing' element={
        <React.Fragment>
           <MyNavbar/>
             <AmazonListing/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}
      
      <Route exact path='/amazon/image-edit' element={
        <React.Fragment>
           <MyNavbar/>
             <AmazonImageEdit/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>


            {/*////////////////////////////////////////////////////////////////////////////// */}


            <Route exact path='/flipkart/image-edit' element={
        <React.Fragment>
           <MyNavbar/>
             <FlipkartImageEdit/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}


      <Route exact path='/flipkart' element={
        <React.Fragment>
           <MyNavbar/>
    <FlipkartPage/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}



<Route exact path='/flipkart/account-management' element={
        <React.Fragment>
           <MyNavbar/>
             <FlipkartAccMgmt/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}


      <Route exact path='/flipkart/advertising' element={
        <React.Fragment>
           <MyNavbar/>
             <FlipkartAd/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}

      <Route exact path='/flipkart/rpd' element={
        <React.Fragment>
           <MyNavbar/>
             <FlipkartRpd/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}

      <Route exact path='/flipkart/reg' element={
        <React.Fragment>
           <MyNavbar/>
             <FlipkartReg/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}

      <Route exact path='/ecommerce' element={
        <React.Fragment>
           <MyNavbar/>
             <Ecom/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>



 {/*////////////////////////////////////////////////////////////////////////////// */}

 <Route exact path='/nykaa' element={
        <React.Fragment>
           <MyNavbar/>
             <Nykaa/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

  {/*////////////////////////////////////////////////////////////////////////////// */}

  <Route exact path='/jiomart' element={
        <React.Fragment>
           <MyNavbar/>
             <JioMart/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

   {/*////////////////////////////////////////////////////////////////////////////// */}

   <Route exact path='/digital-market' element={
        <React.Fragment>
           <MyNavbar/>
             <DigitalMarket/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

   {/*////////////////////////////////////////////////////////////////////////////// */}

   <Route exact path='/product-photo' element={
        <React.Fragment>
           <MyNavbar/>
             <ProductPhoto/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>
      
    {/*////////////////////////////////////////////////////////////////////////////// */}

    <Route exact path='/digital-market/search-engine' element={
        <React.Fragment>
           <MyNavbar/>
             <SearchEngine/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

     {/*////////////////////////////////////////////////////////////////////////////// */}

     <Route exact path='/digital-market/social-media' element={
        <React.Fragment>
           <MyNavbar/>
             <SocialMedia/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}

      <Route exact path='/digital-market/content-market' element={
        <React.Fragment>
           <MyNavbar/>
             <Content/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

       {/*////////////////////////////////////////////////////////////////////////////// */}

       <Route exact path='/digital-market/email-market' element={
        <React.Fragment>
           <MyNavbar/>
             <Email/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>
        {/*////////////////////////////////////////////////////////////////////////////// */}

        <Route exact path='/digital-market/amazon-cam' element={
        <React.Fragment>
           <MyNavbar/>
             <AmazonCamp/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>


                {/*////////////////////////////////////////////////////////////////////////////// */}

                <Route exact path='/digital-market/flipkart-cam' element={
        <React.Fragment>
           <MyNavbar/>
             <FlipkartCamp/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

            {/*////////////////////////////////////////////////////////////////////////////// */}


            <Route exact path='/multi-portals' element={
        <React.Fragment>
           <MyNavbar/>
             <CampaignMultiple/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}


      <Route exact path='/image-editing' element={
        <React.Fragment>
           <MyNavbar/>
             <ImageEditing/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

      {/*////////////////////////////////////////////////////////////////////////////// */}
      {/*////////////////////////////////////////////////////////////////////////////// */}


        {/*////////////////////////////////////////////////////////////////////////////// */}


        <Route exact path='/ajio' element={
        <React.Fragment>
           <MyNavbar/>
             <Ajio/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>
       

         {/*////////////////////////////////////////////////////////////////////////////// */}

         <Route exact path='/meesho' element={
        <React.Fragment>
           <MyNavbar/>
             <Meesho/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

          {/*////////////////////////////////////////////////////////////////////////////// */}

          <Route exact path='/shopsy' element={
        <React.Fragment>
           <MyNavbar/>
             <Shopsy/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

           {/*////////////////////////////////////////////////////////////////////////////// */}

           <Route exact path='/perweb' element={
        <React.Fragment>
           <MyNavbar/>
             <PersonalWebsite/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

           {/*////////////////////////////////////////////////////////////////////////////// */}

           <Route exact path='/myntra' element={
        <React.Fragment>
           <MyNavbar/>
             <Myntra/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>
           {/*////////////////////////////////////////////////////////////////////////////// */}

           <Route exact path='/events' element={
        <React.Fragment>
           <MyNavbar/>
             <EventPage videoId="VE4wygKJ0Yc"/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

           {/*////////////////////////////////////////////////////////////////////////////// */}


           <Route exact path='/all-services' element={
        <React.Fragment>
           <MyNavbar/>
             <AllServices/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>

           {/*////////////////////////////////////////////////////////////////////////////// */}



    <Route exact path='/admin-login/admin-home' element={
        <React.Fragment>
           <AdminHome/>
    <br/><br/>
    
    <br/><br/>
    
        </React.Fragment>
      }/>

     <Route exact path='/admin-login/admin-career' element={
        <React.Fragment>
           <AdminCareer/>
    <br/><br/>
    
    <br/><br/>
    
        </React.Fragment>
      }/>
    
          <Route exact path='/registration' element={
            <React.Fragment>
              <UserRegister/>
              <br/><br/><br/><br/>
              
            </React.Fragment>
          }/>

{/*////////////////////////////////////////////////////////////////////////////// */}


<Route exact path='/admin-login' element={
            <React.Fragment>
              <UserLogin/>
              <br/><br/><br/><br/>
             
            </React.Fragment>
          }/>

{/*////////////////////////////////////////////////////////////////////////////// */}

<Route exact path='/admin-login/admin-home/update-benquiry/:bId' element={
            <React.Fragment>
              <UpdateBusEnq/>
              <br/><br/><br/><br/>
             
            </React.Fragment>
          }/>

{/* /////////////////////////////////////////////////////////////////////////////*/}

<Route exact path='/admin-login/admin-home/update-cenquiry/:posId/:cId' element={
            <React.Fragment>
              <UpdateCarEnq/>
              <br/><br/><br/><br/>
             
            </React.Fragment>
          }/>


{/* /////////////////////////////////////////////////////////////////////////////*/}

<Route exact path='/admin-login/admin-dept' element={
            <React.Fragment>
              <AdminDepartments/>
              <br/><br/><br/><br/>
             
            </React.Fragment>
          }/>

{/* /////////////////////////////////////////////////////////////////////////////////// */}

<Route exact path='/admin-login/admin-all-list' element={
            <React.Fragment>
              <AdminList/>
              <br/><br/><br/><br/>
             
            </React.Fragment>
          }/>


{/* /////////////////////////////////////////////////////////////////////////////////// */}

<Route exact path='/admin-login/all-services' element={
            <React.Fragment>
              <ServicePage/>
              <br/><br/><br/><br/>
             
            </React.Fragment>
          }/>
{/* /////////////////////////////////////////////////////////////////////////  */}

<Route exact path='/web-dev' element={
        <React.Fragment>
           <MyNavbar/>
             <WebDev/>
    <br/><br/>
    
    <br/><br/>
    <Footer/>
        </React.Fragment>
      }/>
        </Routes>
    </Router>
    
    
    </>
  );
}

export default App;

