import React from 'react'
import { useNavigate } from 'react-router-dom'
import f4 from '../../images/f4.jpg'

export default function FlipkartReg() {
  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron ' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>FLIPKART ACCOUNT LAUNCH AND REGISTRATION</h2>
    </div>
    <div className='container'>
     <div className='row'>
    
      <div className='col-4 animate__animated animate__slideInLeft '>
         
           <img src={f4} style={{maxWidth:"100%"}} alt='Freg' className='img-fluid'/>

  
      </div>
      <div className='col-1 '></div>
      <div className='col-7 animate__animated animate__slideInRight '>
        <h5 className=''>Leverage Expertise for Seamless Flipkart Account Launch & Registration</h5>
        <br/><br/>
        <p className='font-size'>At Mazeflip Services, we excel in Flipkart account management, delivering unparalleled support to ensure a seamless journey for our clients. Our dedicated team is equipped with the skills and knowledge to establish a standout presence on Flipkart, guiding you through every step from account creation to product listing and store optimization. By entrusting us with your Flipkart account, you'll sidestep common pitfalls, saving valuable time and resources while gaining access to a professionally curated storefront. With Mazeflip Services by your side, you'll not only launch your store with confidence but also maintain a robust and optimized presence on Flipkart, driving sustained growth and success.</p>
        <br/> <div className='color-change'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>  
      </div>
      
     </div>
    </div>
      
    </>
  )
}
