import React from 'react'
import { useNavigate } from 'react-router-dom'
import em from '../../images/email.jpg'

export default function Email() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>EMAIL MARKETING</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col-4 animate__animated animate__slideInLeft '>
           
             <img src={em} style={{maxWidth:"100%"}} className='img-fluid' alt='Emaildig'/>
  
    
        </div>
        <div className='col-1 '></div>
        <div className='col-7 animate__animated animate__slideInRight '>
          <h5 className=''>Supercharge Customer Acquisition with Targeted Email Marketing</h5>
          <br/><br/>
          <p className='font-size'>Elevate your customer acquisition efforts with our strategic and personalized email marketing solutions. At Mazeflip Services, we specialize in crafting impactful email campaigns that engage and convert prospects into loyal customers. Our team of email marketing experts leverages advanced segmentation and targeting techniques to deliver highly relevant and personalized messages to your audience, driving increased engagement and conversions. From enticing promotional offers and exclusive discounts to informative newsletters and valuable content, we ensure that every email resonates with your audience and inspires action. With our data-driven approach and focus on delivering measurable results, we help you optimize your email marketing efforts for maximum ROI and long-term success. Partner with Mazeflip Services to take your customer acquisition strategy to the next level and unlock new growth opportunities for your business.</p>
          <br/> <div className='color-change'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>  
        </div>
        
       </div>
      </div>
        
      </>
    )
}
