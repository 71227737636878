import React from 'react'
import { useNavigate } from 'react-router-dom'
import f1 from '../../images/f1.jpg'

export default function FlipkartAccMgmt() {
  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>FLIPKART ACCOUNT MANAGEMENT</h2>
    </div>
    <div className='container'>
     <div className='row'>
    
      <div className='col-4 animate__animated animate__slideInLeft '>
         
           <img src={f1} style={{maxWidth:"100%"}} alt='Faccmgmt' className='img-fluid'/>

  
      </div>
      <div className='col-1 '></div>
      <div className='col-7 animate__animated animate__slideInRight '>
        <h5 className=''>Accelerate Your Business Growth with Expert Flipkart Account Management</h5>
        <br/><br/>
        <p className='font-size'>Unlock the potential of your business with Mazeflip Services' dedicated Flipkart account management solutions. Our seasoned account managers are poised to guide you through every aspect of your Flipkart journey, offering tailored advice and support to optimize your operations. From refining your product selection to streamlining delivery processes and fine-tuning pricing strategies, we provide comprehensive assistance to bolster your presence on Flipkart and cultivate a loyal customer base.

Our holistic approach encompasses a range of services designed to propel your e-commerce success on Flipkart. From seamless seller account setup and brand registration to meticulous product listing, efficient order management, and strategic Flipkart ads management, we cover all the essentials to ensure your seller account thrives and your business flourishes. With Mazeflip Services at your side, you can confidently navigate the intricacies of the Flipkart marketplace and capitalize on lucrative opportunities for sustainable revenue growth.</p>
        <br/> <div className='color-change'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>  
      </div>
      
     </div>
    </div>
      
    </>
  )
}
