import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CareerEnquiryItem from '../CareerEnquiryItem'
import { AuthContext } from '../AuthContext'

const BASE_URL = 'https://mazeflipservices.com:44344'; 
//const BASE_URL = 'http://localhost:9090'; 


export default function AdminCareer() {
  const {accessToken, setToken} = useContext(AuthContext);
    const navigate = useNavigate()
   // const[careerPageEnquires, setCareerPageEnquiries]=useState([])
    const [positions, setPositions] = useState([]);

    

/////////////////////////////////////////////////////////////////////////////////



    useEffect(() => {
        // Check if accessToken exists
        if (!accessToken) {
            // If accessToken doesn't exist, redirect to another page
            navigate("/admin-login");
        } else {
            // Fetch positions with associated career pages
            axios.get(`${BASE_URL}/posts/all`, {
                headers: {
                    Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
                }
            })
            .then(response => {
                setPositions(response.data);
                console.log('Positions:', response.data);
            })
            .catch(error => {
                console.error('Error fetching positions:', error);
            });
        }
    }, []);

//////////////////////////////////////////////////////////////////////////////////    

    // useEffect(() => {
    //     if (accessToken) {
    //       const handleCareerPageEnquiries = async () => {
    //         try {
    //           const response = await axios.get('http://localhost:9090/admin/getallcp', {
    //             headers: {
    //               Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
    //             }
    //           });
    //           setCareerPageEnquiries(response.data);
    //           console.log('Career pages:', response.data);
    //         } catch (error) {
    //           console.error('Error fetching business enquiries:', error);
    //         }
    //       };
    //       handleCareerPageEnquiries();
    //     }
    //   }, []);


      const handleDeleteCarEnquiry = async(id) => {
        // Logic to delete the enquiry with the given ID from the state or send a request to the backend
       try {
        
        const response = await axios.delete(`${BASE_URL}/admin/deletecarp/${id}`,{
            headers: {
              Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
            }
          })

          if (response.status === 204) {
            console.log('Deleted successfully')
            window.location.reload(); // Reload the page
          } 

          else if (response.status === 404) {
            console.log('Not found')
          } 


       } catch (error) {
        console.log('Not Deleted ',error)

       }
          
      };

      /////////////////////////////////////////////////////////////////////////

      const handleLogout = async()=>{
        try {

          const response = await axios.post(`${BASE_URL}/admin/logout`,null,
          {
          headers: {
            Authorization: `Bearer ${accessToken}` // Include accessToken in the request headers
          }
        })
        if (response.status ===200) {
          
          localStorage.removeItem('accessToken')
          navigate('/admin-login')
        }
       else if (response.status ===404) {
        
        console.log('No token is there')
       } 
          
        } catch (error) {
          console.log('Some error occurred ',error)
        }

        

      }

      const handleBEnquiry=()=>{
        navigate('/admin-login/admin-home')
      }

      const handleDeptEnquiry=()=>{
        navigate('/admin-login/admin-dept')
      }

  return (
    <>
    <div className='jumbotron p-3'>
   <h2 className='text-center'>ADMIN</h2>
    </div>
    <div className='container' style={{maxWidth:"90%"}}>
      <div className='row'>
       <div className=''>
       <h4 className='text-center'>Career Page</h4>
       <br/>
       </div>
       
      </div>
      <div className='row'>
    <div className='col-sm-6 col-md-3 mb-2 logout-button'> {/* Add margin bottom */}
        <button type='button' className='btn text-white' onClick={handleBEnquiry}>Business Enquiry</button>
    </div>
    <div className='col-sm-6 col-md-3 mb-2 logout-button'> {/* Add margin bottom */}
        <button type='button' className='btn text-white' onClick={handleDeptEnquiry}>Department</button>
    </div>
    <div className='col-sm-6 col-md-3 mb-2'></div>
    
    <div className='col-sm-6 col-md-3 mb-2 logout-button '> {/* Add margin bottom */}
        <button type='button' className='btn text-white' onClick={handleLogout}>Logout</button>
    </div>
</div>

      <br/>
      
      <div className='text-center table-responsive '>
        <table className='table table-bordered table-hover '>
            <thead className='thead-dark'>
            <tr>
            <th>ID</th>
            <th>Full Name</th>
            
            <th>Email</th>
            <th>Contact No:</th>
            
            <th>Position Applied</th>
            {/* <th>Update</th> */}
            <th>Delete</th>
           

            </tr>
            </thead>
            <tbody>
                
            {positions.map((position, index) => (
                                position.careerPages.map((careerPage, idx) => (
                                    <tr key={idx}>
                                        <td>{careerPage.cId}</td>
                                        <td>{careerPage.fullName}</td>
                                        <td>{careerPage.email}</td>
                                        <td>{careerPage.mobileNumber}</td>
                                        <td>{position.posName}</td>
                                        {/* <td>
                                            <div style={{backgroundColor:"green"}} className='p-2' >
                                                <Link to={`/admin-login/admin-home/update-cenquiry/${position.posId}/${careerPage.cId}`} className='text-decoration-none text-white'>
                                                    Update
                                                </Link>
                                            </div>
                                        </td> */}
                                        <CareerEnquiryItem key={careerPage.cId} enquiry={careerPage} onDelete={() => handleDeleteCarEnquiry(careerPage.cId)} />
                                    </tr>
                                ))
                            ))}
                
            </tbody>
            
           
        </table>
      </div>
    </div>
      
    </>
  )
}
