import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function EventPage({videoId}) {
  return (
    <>
    <div className='jumbotron ' style={{ paddingTop: '120px' }}>
    <h2 className='fw-bold text-center'>Events</h2>
    </div>
    <div>
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src={`https://www.youtube.com/embed/${videoId}`}
              allowFullScreen
              title="YouTube video player"
            ></iframe>
          </div>
        </Col>
      </Row>
    </Container>
    </div>
         
      
    </>
  )
}
