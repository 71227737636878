import React from 'react'
import img from '../images/img.jpg'
import { useNavigate } from 'react-router-dom'

export default function ImageEditing() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>IMAGE EDITING</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col-4 animate__animated animate__slideInLeft '>
           
             <img src={img} style={{maxWidth:"100%"}}/>
  
    
        </div>
        <div className='col-1 '></div>
        <div className='col-7 animate__animated animate__slideInRight '>
          <h5 className=''>Professional Image Editing for E-commerce Success</h5>
          <br/><br/>
          <p className='font-size'>Elevate your online store's visual appeal across all e-commerce platforms with our professional image editing services at Mazeflip Services. Our skilled team meticulously refines product images, ensuring they meet the specifications and standards of various e-commerce websites. From background removal to color correction and enhancement, we optimize your product visuals to captivate shoppers and drive conversions. Trust Mazeflip Services for comprehensive image editing solutions tailored to enhance your brand's online presence and boost sales across all e-commerce channels.</p>
          <br/> <div className='color-change'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>  
        </div>
        
       </div>
      </div>
        
      </>
    )
}
