import React from 'react'
import { useNavigate } from 'react-router-dom'
import meesho from '../images/meesho.jpg'

export default function Meesho() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>MEESHO</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col animate__animated animate__pulse animate__infinite	infinite text-center '>
           
             <img src={meesho} style={{maxWidth:"300px"}}/>
  
    
        </div>
        
        
        
       </div>
       <br/>
       <div className='container'>
        <div className='row'>
        
            <div className='col animate__animated animate__slideInRight '>
            <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Meesho Account Management</h4></div> 

          <br/><br/>
          <p className='font-size'>Empower your Meesho selling journey with Mazeflip Services, your trusted partner in e-commerce success. Our dedicated Meesho account management services are tailored to meet the unique needs of sellers like you, providing comprehensive support and guidance every step of the way.</p>
          <p className='font-size'>When you choose Mazeflip Services for your Meesho account management, you gain access to a team of experienced professionals who are committed to helping you maximize your selling potential on the platform. Our Meesho account managers work tirelessly to understand your business goals and develop personalized strategies to help you achieve them.</p>
         
          <p className='font-size'>With Mazeflip Services by your side, you can focus on what you do best – sourcing and selling products – while we handle the rest. Join forces with Mazeflip Services today and unlock the full potential of your Meesho business.</p>
          <br/>   
            </div>


            <div className='col animate__animated animate__slideInRight '>
            <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Meesho Onboarding and Launch</h4></div> 
 
          <br/><br/>
          <p className='font-size'>Launching your Meesho storefront has never been easier thanks to Mazeflip Services. Our seamless onboarding process ensures that you can start selling on Meesho quickly and efficiently, without any hassle.</p>
          <p className='font-size'>When you partner with Mazeflip Services for your Meesho onboarding and launch, you gain access to a wealth of resources and support to help you get started on the platform. Our team will guide you through the process of setting up your Meesho seller account, uploading your product catalog, and optimizing your storefront for maximum visibility and sales.</p>
          <p className='font-size'>With Mazeflip Services by your side, you can launch your Meesho storefront with confidence, knowing that you have a trusted partner to support you every step of the way. Join forces with Mazeflip Services today and take your Meesho business to new heights.</p>
          <br/>   
            </div>


            <div className='col animate__animated animate__slideInRight '>
         <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Meesho Catalog Management (Listing)</h4></div> 
          <br/><br/>
          <p className='font-size'>Elevate your Meesho product listings with Mazeflip Services's catalog management solutions. Our comprehensive tools and resources make it easy to create and optimize your product catalog for maximum visibility and sales on Meesho.</p>
          <p className='font-size'>With Mazeflip Services's catalog management solutions, you can easily upload and manage your product listings on Meesho, ensuring that your products are presented in the best possible light to potential customers. Our intuitive platform allows you to add detailed descriptions, high-quality images, and accurate specifications to showcase your products effectively.</p>
          <p className='font-size'>With Mazeflip Services by your side, you can create compelling product listings that attract customers and drive sales on Meesho. Join forces with Mazeflip Services today and take your Meesho business to the next level.</p>
          <br/>   
            </div>
            

        </div>
       </div>
         <div className='color-change text-center'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>
      </div>
        
      </>
    )
}
