import React from 'react'
import aie from '../../images/aie.jpg'
import { useNavigate } from 'react-router-dom'

export default function AmazonImageEdit() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>AMAZON IMAGE EDITING</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col-4 animate__animated animate__slideInLeft '>
           
             <img src={aie} style={{maxWidth:"100%"}} alt='AImage' className='img-fluid'/>
  
    
        </div>
        <div className='col-1 '></div>
        <div className='col-7 animate__animated animate__slideInRight '>
          <h5 className=''>Professional Amazon Image Editing Services</h5>
          <br/><br/>
          <p className='font-size'>Elevate your Amazon product listings with professional image editing services offered by Mazeflip Services. Our skilled team meticulously enhances product images to meet Amazon's stringent standards, ensuring they stand out and captivate potential buyers. From background removal to color correction and image resizing, we ensure your product images are optimized for maximum visibility and appeal, ultimately boosting sales and driving growth on the Amazon marketplace.</p>
          <br/> <div className='color-change'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>  
        </div>
        
       </div>
      </div>
        
      </>
    )
}
