import React from 'react'
import { useNavigate } from 'react-router-dom'
import shop from '../images/shop.jpg'

export default function Shopsy() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>SHOPSY</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col animate__animated animate__pulse animate__infinite	infinite text-center '>
           
             <img src={shop} style={{maxWidth:"300px"}}/>
  
    
        </div>
        
        
        
       </div>
       <br/>
       <div className='container'>
        <div className='row'>
        
            <div className='col animate__animated animate__slideInRight '>
            <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Shopsy Account Management</h4></div> 

          <br/><br/>
          <p className='font-size'>Managing your seller account on Shopsy requires meticulous attention to detail and a proactive approach to ensure optimal performance and results. At Mazeflip Services, we offer comprehensive account management services tailored to meet the unique needs of sellers on Shopsy's platform.</p>
          <p className='font-size'>Our experienced team of account managers works closely with sellers to oversee all aspects of their Shopsy accounts, including inventory management, order processing, customer service, and performance monitoring. We take a proactive approach to identify opportunities for growth and optimization, helping sellers maximize their presence on Shopsy and drive sales.</p>
         
          <p className='font-size'>Partner with Mazeflip Services for expert account management on Shopsy and unlock the full potential of your seller account.</p>
          <br/>   
            </div>


            <div className='col animate__animated animate__slideInRight '>
            <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Shopsy Onboarding and Launch</h4></div> 
 
          <br/><br/>
          <p className='font-size'>Embarking on your journey as a seller on Shopsy presents a lucrative opportunity to tap into a vast network of customers and expand your reach in the digital marketplace. At Mazeflip Services, we specialize in providing comprehensive onboarding and launch assistance to brands looking to establish a presence on Shopsy's esteemed platform.</p>
          <p className='font-size'>Our dedicated team works tirelessly to guide brands through the intricacies of the onboarding process, ensuring a seamless transition from setup to launch. From account setup and brand registration to product listing and inventory management, we handle all aspects of the onboarding journey with precision and expertise.</p>
          <p className='font-size'>Partner with Mazeflip Services to unlock the full potential of selling on Shopsy and position your brand for success in the competitive world of e-commerce.</p>
          <br/>   
            </div>


            <div className='col animate__animated animate__slideInRight '>
         <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Shopsy Catalog Management (Listing)</h4></div> 
          <br/><br/>
          <p className='font-size'>A compelling and well-curated product catalog is essential for capturing the attention of customers on Shopsy and driving conversions. At Mazeflip Services, we offer comprehensive cataloging services designed to enhance the visibility and appeal of your products on Shopsy's platform.</p>
          <p className='font-size'>Our experienced team of cataloging experts specializes in creating engaging product listings that showcase the unique features and benefits of each item. From high-quality images and descriptive product descriptions to accurate categorization and keyword optimization, we ensure that your products stand out in Shopsy's extensive catalog.</p>
          <p className='font-size'>Partner with Mazeflip Services to elevate your product catalog on Shopsy and attract more customers to your brand. With our cataloging expertise, you can showcase your products in the best possible way and drive sales on one of India's leading e-commerce platforms.</p>
          <br/>   
            </div>
            

        </div>
       </div>
         <div className='color-change text-center'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>
      </div>
        
      </>
    )
}
