import React from 'react'
import f2 from '../../images/f2.jpg'
import { useNavigate } from 'react-router-dom'

export default function FlipkartAd() {
  
  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>FLIPKART ADVERTISING</h2>
    </div>
    <div className='container'>
     <div className='row'>
    
      <div className='col-4 animate__animated animate__slideInLeft '>
         
           <img src={f2} style={{maxWidth:"100%"}} alt='Fad' className='img-fluid'/>

  
      </div>
      <div className='col-1 '></div>
      <div className='col-7 animate__animated animate__slideInRight '>
        <h5 className=''>Maximize Your E-Commerce Potential with Strategic Flipkart Advertising</h5>
        <br/><br/>
        <p className='font-size'>Unlock unparalleled growth opportunities for your retail e-commerce venture through Mazeflip Services' comprehensive Flipkart advertising solutions. With our in-depth understanding of Flipkart's ecosystem and consumer behavior, we craft bespoke advertising campaigns tailored to resonate with your target audience.

By leveraging Flipkart advertising, you can precisely target your products to the most relevant audience segments, amplifying your visibility and expanding your customer base. Our seasoned e-commerce marketing specialists employ cutting-edge ad formats and targeting techniques to ensure optimal campaign performance and ROI.

From campaign inception to execution and optimization, our dedicated team of professionals is with you every step of the way. We provide detailed campaign insights and data-driven recommendations to fine-tune your strategies for maximum cost-effectiveness and results.

At Mazeflip Services, our commitment to excellence drives us to deliver top-tier Flipkart advertising services that propel your brand towards sustained success. Partner with us today to harness the full potential of Flipkart advertising and elevate your e-commerce business to new heights.</p>
        <br/> <div className='color-change'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>  
      </div>
      
     </div>
    </div>
      
    </>
  )
}
