import React from 'react'
import f3 from '../../images/f3.jpg'
import { useNavigate } from 'react-router-dom'

export default function FlipkartRpd() {
  
  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>FLIPKART RPD</h2>
    </div>
    <div className='container'>
     <div className='row'>
    
      <div className='col-4 animate__animated animate__slideInLeft '>
         
           <img src={f3} style={{maxWidth:"100%"}} alt='Frpd' className='img-fluid'/>

  
      </div>
      <div className='col-1 '></div>
      <div className='col-7 animate__animated animate__slideInRight '>
        <h5 className=''>Unlock E-Commerce Success with Compelling Rich Product Descriptions (RPD)</h5>
        <br/><br/>
        <p className='font-size'>In the dynamic realm of e-commerce, Rich Product Descriptions (RPD) stand as a pivotal element, serving as a catalyst for customer engagement, sales acceleration, and long-term customer loyalty. At Mazeflip Services, we understand the transformative power of RPD and its profound impact on establishing seller credibility and fostering brand trustworthiness.

With a proven track record of nurturing brand goodwill over the years, Mazeflip Services excels in crafting captivating Front Stores tailored to elevate brands on Flipkart. Our expertise extends beyond mere storefront aesthetics; we specialize in curating brand-aligned written content for product descriptions and captivating visual graphic content to enhance brand recognition and bolster customer retention.In an ever-evolving e-commerce landscape, neglecting the importance of RPD can inadvertently hinder seller visibility and impede growth potential. Mazeflip Services is here to ensure that sellers harness the full potential of RPD to garner the exposure and recognition they rightfully deserve.</p>
        <br/> <div className='color-change'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>  
      </div>
      
     </div>
    </div>
      
    </>
  )
}

