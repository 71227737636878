import React from 'react'
import './AmazonStore.css'
import a6 from '../../images/a6.jpg'
import { useNavigate } from 'react-router-dom'

export default function AmazonStore() {
  const navigate = useNavigate()

  const handleAccMgmt = ()=>{
      navigate('/buseq')
  }

  return (
    <>
    <div className='jumbotron' style={{ paddingTop: '120px' }}>
     <h2 className='text-center fw-bold'>AMAZON STOREFRONT CREATION</h2>
    </div>
    <div className='container'>
     <div className='row'>
    
      <div className='col-4 animate__animated animate__slideInLeft '>
         
           <img src={a6} style={{maxWidth:"100%"}} alt='Astore'  className='img-fluid'/>

  
      </div>
      <div className='col-1 '></div>
      <div className='col-7 animate__animated animate__slideInRight '>
        <h5 className=''>Revamp Your Amazon Storefront with Personalized Touches.</h5>
        <br/><br/>
        <p className='font-size'>Craft an irresistible Amazon storefront to captivate your audience, curating a browsing journey centered around your brand. Elevate your customers' shopping experience by showcasing your product range through a bespoke brand store design tailored to your objectives. At Mazeflip Services, we deploy a team of strategic and creative minds to deliver unique and customized designs for your Amazon Storefront. Our tailored storefronts offer a curated showcase of your diverse product portfolio, ensuring a royal treatment for your customers on Amazon's platform. With a keen eye on aesthetics and personalized branding, our team ensures your storefront aligns perfectly with your brand's vision. Mazeflip Services provides a cost-effective package, seamlessly integrating cross-platform product promotion to enhance your Amazon seller experience.</p>
        <br/> <div className='color-change'>
          <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
          </div>  
      </div>
      
     </div>
    </div>
      
    </>
  )
}
