import React from 'react'
import { useNavigate } from 'react-router-dom'
import per from '../images/per.jpg'

export default function PersonalWebsite() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>PERSONAL WEBSITE</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col animate__animated animate__pulse animate__infinite	infinite text-center '>
           
             <img src={per} style={{maxWidth:"300px"}}/>
  
    
        </div>
        
        
        
       </div>
       <br/>
       <div className='container'>
        <div className='row'>
        
            <div className='col animate__animated animate__slideInRight '>
            <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Personal Website Account Management</h4></div> 

          <br/><br/>
          <p className='font-size'>Managing your personal website's account is essential for ensuring smooth operations, maximizing functionality, and enhancing user experience. At Mazeflip Services, we offer personalized account management solutions tailored to meet the unique needs of your personal website. Our dedicated team of account management specialists works closely with you to understand your goals, preferences, and requirements, ensuring that your website's account is optimized for success.</p>
          <p className='font-size'>From setting up your account and configuring account settings to monitoring performance metrics and implementing updates, we handle all aspects of account management with precision and expertise. We strive to streamline processes, improve efficiency, and enhance security to provide you with a seamless account management experience. With Mazeflip Services by your side, you can trust that your personal website's account is in capable hands, allowing you to focus on creating content, engaging with your audience, and achieving your online objectives.</p>
         
          
          <br/>   
            </div>


           


            <div className='col animate__animated animate__slideInRight '>
         <div className='p-3' style={{backgroundColor:"#7E014D"}}><h4 className='text-center text-light'>Personal Website Cataloging (Listing)</h4></div> 
          <br/><br/>
          <p className='font-size'>Effectively cataloging your personal website's content is crucial for organizing information, improving navigation, and enhancing user engagement. At Mazeflip Services, we specialize in cataloging (listing) solutions designed to optimize the presentation and accessibility of your website's content. Our experienced team of cataloging experts works diligently to categorize, tag, and organize your content in a logical and user-friendly manner, ensuring that visitors can easily find and navigate through your website.</p>
          <p className='font-size'>We employ industry-leading techniques and best practices to ensure that your content is accurately indexed, searchable, and discoverable. From creating descriptive titles and meta tags to structuring content hierarchies and implementing navigation menus, we take a comprehensive approach to cataloging your website's content. Our goal is to provide users with a seamless browsing experience that encourages exploration, engagement, and interaction with your content.</p>
          <p className='font-size'>By leveraging our cataloging (listing) services, you can showcase your personal website's content in the best possible light, attracting more visitors, retaining their interest, and ultimately achieving your online objectives. With Mazeflip Services as your partner, you can unlock the full potential of your personal website and maximize its impact on your audience.</p>
          <br/>   
            </div>
            

        </div>
       </div>
         <div className='color-change text-center'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>
      </div>
        
      </>
    )
}
