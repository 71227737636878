import React from 'react'
import { useNavigate } from 'react-router-dom'
import social from '../../images/social.jpg'

export default function SocialMedia() {
    const navigate = useNavigate()

    const handleAccMgmt = ()=>{
        navigate('/buseq')
    }
  
    return (
      <>
      <div className='jumbotron' style={{ paddingTop: '120px' }}>
       <h2 className='text-center fw-bold'>SOCIAL MEDIA MARKETING</h2>
      </div>
      <div className='container'>
       <div className='row'>
      
        <div className='col-4 animate__animated animate__slideInLeft '>
           
             <img src={social} style={{maxWidth:"100%"}} alt='Social' className='img-fluid'/>
  
    
        </div>
        <div className='col-1 '></div>
        <div className='col-7 animate__animated animate__slideInRight '>
          <h5 className=''>Establish Your Brand Through Strategic Social Media Marketing</h5>
          <br/><br/>
          <p className='font-size'>Elevate your brand's presence and engage your audience effectively with our strategic social media marketing solutions. Mazeflip Services specializes in crafting compelling social media campaigns that resonate with your target audience and drive meaningful engagement. Our team of social media experts combines creativity with data-driven insights to develop customized strategies tailored to your brand's unique goals and objectives. From creating captivating content and managing your social media profiles to implementing targeted advertising campaigns and analyzing performance metrics, we provide end-to-end solutions to help you build and strengthen your brand presence across all major social media platforms. With our proven expertise and innovative approach, we empower brands to connect with their audience authentically, foster brand loyalty, and drive measurable results. Partner with Mazeflip Services to unlock the full potential of social media and take your brand to new heights of success.





</p>
          <br/> <div className='color-change'>
            <button type='button' className='btn text-white' onClick={handleAccMgmt}>Request a Quote</button>
            </div>  
        </div>
        
       </div>
      </div>
        
      </>
    )
}
